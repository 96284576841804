.showSideWrapper {
    background-color: #e41c39;
    position: fixed;
    padding: 4px 7px 1px 7px;
    border-radius: 0 7px 7px 0;
    left: 0;
    top: 60px;
    z-index: 100;
    cursor: pointer;

    .showSideBarIcon {
        font-size: 24px;
        color: white;

        &:focus {
            outline: 0;
        }
    }
}
.side-bar {
    min-height: calc(100vh - 75px - 44px);
    width: 300px !important;
    background-color: #6f6f6f;
    font-family: 'Work Sans', sans-serif;

    .fixed-position {
        position: sticky;
        top: 0;
        padding-top: 40px;

        .hideSideWrapper {
        
            position: absolute;
            padding: 4px 7px 1px 7px;
            border-radius: 7px 0 0 7px;
            right: 0;
            top: 5px;
            cursor: pointer;
    
            &:hover {
                background-color: #e41c39;
            }
        
            .hideSideBarIcon {
                font-size: 24px;
                color: white;            
        
                &:focus {
                    outline: 0;
                }
            }
        }
    
        .parent-menu {
             padding: 15px 20px 15px 40px;
             color: white;
             font-weight: 600;
             border-right: 5px solid #6f6f6f;
             cursor: pointer;
             width: 300px;
    
             &.with-sub-menu {
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                p {
                    margin: 0;
                }
    
                button {
                    background-color: rgba($color: lightgrey, $alpha: 0.3);
                    border: 1px solid #aaaaaa;
                    border-radius: 50%;
                    width: 24px;
                    height: 24px;
                    color: white;
                    font-size: 13px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    
                    &:focus {
                        outline: 0;
                    }
                }
             }
    
             &:hover {
                 background-color: grey;
                 border-color: lightgrey;
             }
    
             &.active {
                background-color: #e41c39;
                border-color: white;
            }
        }
    
        .sub-menu {
            padding: 10px 20px 10px 45px;
            color: #a3a3a3;
            font-weight: 400;
            cursor: pointer;
    
            &.sub-prod {
                padding-left: 55px;
    
                .fas {
                    font-size: 10px;
                    margin-right: 7px;
                }
    
                &.active {
                    color: white;
                    background-color: #8f8f8f;
                }
            }
    
            &:hover {
                color: white;
                background-color: #7f7f7f;
            }
    
            &.active {
                color: white;
                background-color: #7f7f7f;
            }
    
            .fas {
                margin-right: 10px;
            }
       }
    }
}

.with-extra-icon{
    display: flex;
    justify-content: space-between;
}

.bullet-icon{
    
    position: relative;
    display: flex;
    flex-direction: column-reverse;

    & > img{
        width: 30px;
        height: 30px;    
        transform: rotate(90deg);
        margin: 0;
        padding: 0;
    }

    & > div{
        height: auto;
        width: auto;
        border-radius: 5px;
        max-width: 32px;
        font-size: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: bold;
    }
}


.extra-counter{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    & > .extra-counter-link{
        display: flex;
        width: 100%;
        justify-content: space-between;
        height: 100%;
    }

    & > .extra-counter-counter{
        font-weight: bold;
        width: 100%;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);
        
        &:nth-child(2){
            margin-left: 10px;
        }

        & > img{
            transform: rotate(90deg);

        }
    }
}