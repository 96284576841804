.section {
    
    .total-count-div {
        background-color: #535353;
        margin: 0 -14px 0 -14px;
        padding: 8px 15px;
        border: 1px solid grey;
        
        span {
            color: #a3a3a3;
        }
    }
}
