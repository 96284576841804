.table-row {
    cursor: pointer;

    &.expired {
        // background-color: #912c2c !important;
        border-bottom: 1px solid #670000;
        border: 4px solid #912c2c !important;
    }

    .ft-18 {
        font-size: 18px;
    }
}

.ffl-row {
    padding: 15px 0 15px 10px;
    align-items: center;
    font-size: 14px;
    color: #ddd;
    background-color: #6a6a6a;
    border-top: 2px solid #555555;
    position: relative;

    &.custom{
        border-top: none !important;
        
        &::after{
            content: "";
            background: #404040;
            position: absolute;
            bottom: 0;
            right: 0;
            height: 2px;
            width: calc(100% - 3px);
        }
        
    }

    &.bottom-border {
        border-top: none;
        //border-bottom: 5px solid #555555;
        background-color: #777777;
    }
}
.complete-webinar-row {
    margin-left: 15px;
    padding: 15px 0 15px 10px;
    align-items: center;
    font-size: 14px;
    color: #d7d7d7;
    background-color:#565555;

    &-white{
        border-right: 5px solid white !important;
    }
    &-yellow{
        border-right: 5px solid rgb(211,172,0) !important;
    }
    &-green{
        border-right: 5px solid rgb(99,173,48) !important;
    }


    &.bottom-border {
        border-top: none;
        background-color: #6c6b6b;
        color: #e5e0e0;
    }
}

.non-ffl-row {
    padding: 15px 0 15px 10px;
    margin: 0 -15px 0 -15px;
    color: #eeeeee;
    background-color: #777777;
    border-bottom: 1px solid #555555;
}

.dateWrapper {

    &.fflDate {
        .react-datepicker-wrapper {
            input {
                width: 90px;
            }
        }
    }    
}

.full-size-img-wrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    padding: 30px 50px;

    .full-size-img {
        background-color: white;
        border-radius: 12px;
        box-shadow: 0 0 14px 3px #a6e4ec;
        min-width: 50%;
        max-width: 90%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .closeIcon {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 30px;
            text-shadow: 2px 1px white;
            cursor: pointer;
            color: black;
        }

        button {
            position: absolute;
            bottom: 20px;
            background-color: transparent;
            border-radius: 10px;

            &:focus {
                outline: 0;
            }

            &.replace {
                left:20px;
                border: 2px solid #32a2a2;
                color: #32a2a2;
            }

            &.delete {
                right: 20px;
                border: 2px solid rgb(156, 21, 21);
                color: rgb(156, 21, 21);
            }
        }

        label {
            margin: 0 100px;
            color: black;
            cursor: pointer;
            font-size: 12px;           

            span {
                font-size: 18px;                
                color: grey;
                text-decoration: underline;
            }
        }

        img {
            max-width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }
}

.ffl-edit {
    width: 100%;
    background-image: url('../../../assets/imgs/background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 25px;
    font-family: "Work Sans", sans-serif;

    p {
        color: #a3a3a3;
        margin-bottom: 5px;

        &.label-p {
            font-size: 14px;
        }
    }
    @mixin shrinkLabel {
        top: -17px;
        font-size: 13px;
        color: white;    
    }

    .row {
        margin: 0;
    }
        .boton{
            width: 100%;
        height: 50px;
        border-radius: 30px;
        border: none;
        background-color: #e41c39;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0062cc;
        }
    .headerRow {
        padding-bottom: 20px;

        .form-button {
            height: 44px;
        }
    }

    .section {
        width: 100%;
        background-color: #5f5f5f;
        padding: 20px 30px 0px 30px;

        .status-div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .status-group {
                display: flex;
                justify-content: right;

                .status-wrapper {
                    padding: 0 0 0 30px;
    
                    p {
                        font-size: 12px;
                        margin-bottom: 0;
                    }
                }
            }
    
            // .react-switch-bg {
            //     div {
            //         &:first-of-type {
            //             color: white;
            //             font-weight: 700;
            //             left: 14px !important;
            //             top: 4px !important;
            //         }
    
            //         &:last-of-type {
            //             color: white;
            //             font-weight: 700;
            //             top: 4px !important;
            //             right: 20px !important;
            //         }
            //     }
            // }
        }

        .taxable-wrapper {
            display: flex;
            align-items: center;

            p {
                padding: 0;
                margin: 0 10px;
            }
        }

        .custom-datepicker {
            width: 100%;
            background-color: #777777;
            border-radius: 5px;
            color: #a3a3a3;
        
            .datepicker-header {
                border: 1px solid lightgray;
                border-radius: 5px;
                padding: 10px 15px;
            }
        
            .date-select {
                display: flex;
                justify-content: space-between;
                padding: 0 70px;
        
                p {
                    cursor: context-menu;
        
                    &.pointer {
                        cursor: pointer;
                    }
                }
            }
        
            .form-button {
                background-color: #8c8c8c;
            }
        
        }

        .custom-input {
            background-color: #393535;
            border: 1px solid grey;
            border-radius: 5px;
            width: 100%;
            height: 40px;
            padding: 10px 15px;
            color: #ddd;
            border-radius: 30px;

            &:focus {
                outline: 0;
            }
        }

        .form-select-container {
            margin: 0;

            .form-select {
                background-color: #555;
                height: 40px;
                border-radius: 5px;
                border: 1px solid grey;
                padding: 10px 15px;
                color: #ddd;

                &:focus {
                    outline: 0;
                }

                .select-down-icon {
                    color: #ddd;
                }

                .label-span {
                    color: #ddd;
                }
            }

            .option-div {
                border: 1px solid grey;
                border-radius: 5px;
                margin: 0;
                width: 100%;
                background-color: #555;

                .option-item {
                    border-bottom: 1px solid grey;

                    &:first-of-type {
                        border-radius: 0;
                    }

                    &:last-of-type {
                        border-radius: 0;
                        border-bottom: none;
                    }
                }
            }
        }

        textarea {
            overflow: auto;
            resize: none;
            width: 100%;
            border: 1px solid grey;
            border-radius: 5px;
            padding: 15px;
            max-height: 300px;
            background-color: #555;
            color: #ddd;
            -ms-overflow-style: none;
    
            &::-webkit-scrollbar {
                display: none;
            }
    
            &:focus {
                outline: 0;
            }
        }

        hr {
            background-color: grey;
        }
    }
    .section-Edit {
        width: 100%;
        background-color: #5f5f5f;
        padding: 0px 30px 30px 30px;

        .status-div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .status-group {
                display: flex;
                justify-content: right;

                .status-wrapper {
                    padding: 0 0 0 30px;
    
                    p {
                        font-size: 12px;
                        margin-bottom: 0;
                    }
                }
            }
    
            // .react-switch-bg {
            //     div {
            //         &:first-of-type {
            //             color: white;
            //             font-weight: 700;
            //             left: 14px !important;
            //             top: 4px !important;
            //         }
    
            //         &:last-of-type {
            //             color: white;
            //             font-weight: 700;
            //             top: 4px !important;
            //             right: 20px !important;
            //         }
            //     }
            // }
        }

        .taxable-wrapper {
            display: flex;
            align-items: center;

            p {
                padding: 0;
                margin: 0 10px;
            }
        }

        .custom-datepicker {
            width: 100%;
            background-color: #777777;
            border-radius: 5px;
            color: #a3a3a3;
        
            .datepicker-header {
                border: 1px solid lightgray;
                border-radius: 5px;
                padding: 10px 15px;
            }
        
            .date-select {
                display: flex;
                justify-content: space-between;
                padding: 0 70px;
        
                p {
                    cursor: context-menu;
        
                    &.pointer {
                        cursor: pointer;
                    }
                }
            }
        
            .form-button {
                background-color: #8c8c8c;
            }
        
        }

        .custom-input {
            background-color: #555;
            border: 1px solid grey;
            border-radius: 5px;
            width: 100%;
            height: 40px;
            padding: 10px 15px;
            color: #ddd;
            border-radius: 30px;

            &:focus {
                outline: 0;
            }
        }

        .form-select-container {
            margin: 0;

            .form-select {
                background-color: #555;
                height: 40px;
                border-radius: 5px;
                border: 1px solid grey;
                padding: 10px 15px;
                color: #ddd;

                &:focus {
                    outline: 0;
                }

                .select-down-icon {
                    color: #ddd;
                }

                .label-span {
                    color: #ddd;
                }
            }

            .option-div {
                border: 1px solid grey;
                border-radius: 5px;
                margin: 0;
                width: 100%;
                background-color: #555;

                .option-item {
                    border-bottom: 1px solid grey;

                    &:first-of-type {
                        border-radius: 0;
                    }

                    &:last-of-type {
                        border-radius: 0;
                        border-bottom: none;
                    }
                }
            }
        }

        textarea {
            overflow: auto;
            resize: none;
            width: 100%;
            border: 1px solid grey;
            border-radius: 5px;
            padding: 15px;
            max-height: 300px;
            background-color: #555;
            color: #ddd;
            -ms-overflow-style: none;
            border-radius: 30px;
    
            &::-webkit-scrollbar {
                display: none;
            }
    
            &:focus {
                outline: 0;
            }
        }

        hr {
            background-color: grey;
        }
    }
    .phone-group {
        position: relative;
        margin: 15px 0;
        font-family: 'PT Sans', sans-serif;

        .phone-input {
            background: none;
            background-color: transparent;
            color: #a0a0a0;
            font-size: 20px;
            padding: 10px 20px 10px 30px;
            display: block;
            width: 100%;
            border: 1.5px solid #a3a3a3;
            border-radius: 30px;
            margin: 0;
            height: 50px;

            &.red-outline {
                border-width: 1px;
                box-shadow: 0 0 5px 3px #ff3547;
            }

            &:focus {
                outline: none;
            }

            &:focus ~ .phone-input-label {
                @include shrinkLabel();
            }
        }

        .phone-input-label { 
            color: #a0a0a0;
            font-size: 18px;
            font-weight: normal;
            position: absolute;
            pointer-events: none;
            left: 25px;
            top: 13px;
            transition: 300ms ease all;

            &.red-label {
                color: #ff3547 !important;
            }

            &.shrink {
                @include shrinkLabel();
            }
        }
    }

}

@media (max-width: 575.98px) { 
    .ffl-row {
        padding: 10px 0 10px 0px;
        font-size: 12px;
    }
        
    .full-size-img-wrapper {
        padding: 20px 10px;

        .full-size-img {
            min-width: 94%;
            max-width: 94%;
            height: 50%;

            .closeIcon {
                top: 10px;
                right: 10px;
                font-size: 20px;
            }

            button {              
                bottom: 10px;            

                &.replace {
                    left:10px;                  
                }

                &.delete {
                    right: 10px;
                }
            }

            label {
                margin: 0 20px;
            }
        }
    }
}

@media (min-width: 575.98px) and (max-width: 769px) {
    .full-size-img-wrapper {
        padding: 20px 10px;

        .full-size-img {

            .closeIcon {
                top: 10px;
                right: 10px;
                font-size: 20px;
            }

            button {              
                bottom: 10px;            

                &.replace {
                    left:10px;                  
                }

                &.delete {
                    right: 10px;
                }
            }

            label {
                margin: 0 20px;
            }
        }
    }
}