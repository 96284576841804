.table-row {            
    padding: 15px 0 15px 10px;
    align-items: center;
    font-size: 13px;
    color: #a3a3a3;  
    
    &:nth-child(even){
        background-color: #666666;
    }

    .fas {
        cursor: pointer;
    }

    input {
        &.edit {
            color: #a3a3a3;
            background-color: transparent;
            border: 1.2px solid grey;
            border-radius: 7px;
            padding: 5px 7px;

            &:focus {
                outline: 0;
            }
        }

        &.empty {
            box-shadow: 0 0 5px 3px #ff3547;
        }
    }

    .form-select-container {
        margin: 0;

        .form-select {
            border-width: 1.2px;
            height: auto;
            padding: 5px 20px;
        }
    }

    .editIcon {
        cursor: pointer;
        font-size: 20px;

        &:first-of-type {
            margin-right: 15px;

            &:hover {
                color: #33c23e;
            }
        }

        &:hover {
            color: #d64444;
        }
    }
}

@media (max-width: 575.98px) { 
    .table-row {

        .col-1, .col-2, .col-3, .col-4 {
            padding-right: 0;
        }

        .editIcon {
    
            &:first-of-type {
                margin-right: 8px;
            }
        }

        input {
            &.edit {
                width: 100%;
            }
        }

        .form-select-container {    
            .form-select {
                padding: 5px 10px;
            }

            .option-div {
                .option-item {
                    padding: 5px 10px;
                }
            }
        }
    }
}
@media (min-width: 576px) and (max-width: 768px) {
    .table-row {
        
        .editIcon {
    
            &:first-of-type {
                margin-right: 8px;
            }
        }

        input {
            &.edit {
                width: 100%;
            }
        }

        .form-select-container {    
            .form-select {
                padding: 5px 10px;
            }

            .option-div {
                .option-item {
                    padding: 5px 10px;
                }
            }
        }
    }
}