
.forgot-password-page {
    font-family: 'Work Sans', sans-serif;
    margin: auto;

    .reqCodeForm {
        padding: 50px;
        width: 500px;
        height: auto;
        background-color: #5f5f5f;
        border-radius: 10px;
        box-shadow: 3px 3px 20px black;
        text-align: center;
    
        .req-title {
            color: white;
            margin-bottom: 45px;
            font-weight: 700;
        }
    
        .emailExistLabel {
            color: #a0a0a0;
            width: 90%;
            margin: auto;
            margin-top: -10px;
            margin-bottom: 30px;
        }
    }
}

@media (max-width: 575.98px) { 
    .forgot-password-page {        
        .reqCodeForm {
            width: 340px;
            padding: 30px;
        }
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .forgot-password-page {
        padding: 30px;
        .reqCodeForm {
            padding: 40px;
        }
    }
}