.ffl-row {
    img {
        width: 50px;
        height: 48px;
        border-radius: 3px;
    }

    button {
        background-color: #717171;
        color: white;
        font-size: 12px;
        padding: 4px 8px;
        border: 1.2px solid #a3a3a3;
        border-radius: 30px;

        &:hover {
            border-color: white;
            background-color: #a3a3a3;
        }

        &:focus {
            outline: 0;
        }
    }
}

.elipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}