.settings-page {
    font-family: "Work Sans", sans-serif;
    width: 100%;
    background-image: url('../../assets/imgs/background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 24px;

    .general-setting {
        width: 100%;
        padding: 30px 40px 50px 40px;
        background-color: #515151;
        
    
        .disable-comment {
            display: flex;
            justify-content: space-between;
            align-items:  center;
            border-top: 1px solid grey;
            border-bottom: 1px solid grey;
    
            &:first-of-type {
                border-bottom: none;
            }

            &:last-of-type {
                border-bottom: none;
            }
        }

        .flexDiv {
            display: flex;
            justify-content: space-between;
            align-items:  center;
        }
    
        textarea {
            overflow: auto;
            resize: none;
            width: 100%;
            border: 1px solid grey;
            border-radius: 7px;
            padding: 16px 22px;
            max-height: 300px;
            background-color: #444444;
            color: grey;
            -ms-overflow-style: none;

            &.no-back {
                background-color: transparent;
                border: 1.5px solid #a3a3a3;
                border-radius: 20px;

                &.invalid {
                    border-width: 1px;
                    box-shadow: 0 0 5px 3px #ff3547;
                }
            }
    
            &::-webkit-scrollbar {
                display: none;
            }
    
            &:focus {
                outline: 0;
            }
    
            &::placeholder {
                color: grey;
            }
    
            &::-webkit-input-placeholder {
                color: grey;
            }
            
            &::-moz-placeholder {  /* Firefox 19+ */
                color: grey;
            }
            
            &:-ms-input-placeholder {
                color: grey;  
            }
        }
    
        .btn-200 {
            padding: 10px;
            width: 200px;
        }

        .group {
            margin: 0;

            .form-input {
                margin: 0;
            }
        }

        .select-back-img-wrapper {
            display: flex;
            flex-wrap: wrap;

            .select-upload-img {
                width: 100px;
                height: 100px;
                border-radius: 14px;
                border: 1.5px dashed grey;
                padding: 5px;
                margin-right: 20px;

                div {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    background-color: #444444;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    label {
                        color: white;
                        margin-bottom: 0;
                        cursor: pointer;
                        
                        &:first-of-type {
                            font-size: 28px;
                        }

                        &:last-of-type {
                            font-size: 12px;
                        }
                    }
                }
            }
            
            .pre-back-img {
                width: 100px;
                height: 100px;
                border-radius: 10px;
                border: 1px solid #666666;
                margin-right: 20px;
                margin-bottom: 20px;
                padding: 2px;

                &:hover {
                    box-shadow: 0 0 5px #e41c39;
                    border-color: #e41c39;
                    cursor: pointer;
                }

                &.active {
                    box-shadow: 0 0 5px #e41c39;
                    border-color: #e41c39;
                }

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                }
            }
        }

        .background-date-wrapper {
            border-radius: 30px;
            padding: 0 14px;
            border: 1.5px solid grey;
            color: #a3a3a3;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 50px;
            margin-bottom: 20px;

            .react-datepicker-wrapper {
                input {
                    width: 100%;
                    background-color: transparent;
                    border: none;
                    color: #a3a3a3;
                    border-right: 1px solid grey;
        
                    &:focus {
                        outline: 0;
                    }
                }
            }

            .datePickerIcon {
                padding-left: 10px;
                cursor: pointer;
            }
        }
    }

    hr {
        background-color: grey;
    }

    .react-switch-bg {
        height: 34px !important;
        padding: 3px !important;
        width: 64px !important;
        border-radius: 30px !important;

        div:nth-of-type(2) {
            top: 2px !important;
        }
    }

    .react-switch-handle {
        top: 4px !important;
        left: 4px !important;
    }

    .color-btn {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        border: 2px solid grey;
        margin-right: 10px;

        &.c1 {
            background-color: white;
        }

        &.c2 {
            background-color: #727d75;
        }

        &.c3 {
            background-color: #3b443f;
        }

        &.c4 {
            background-color: #a85b49;
        }

        &.c5 {
            background-color: #e19865;
        }

        &.c6 {
            background: linear-gradient(90deg, rgba(50,120,228,1) 0%, rgba(86,209,36,1) 32%, rgba(253,29,29,1) 67%, rgba(242,252,69,1) 100%);
        }

        &.actived {
            box-shadow: 0 0 5px 5px red;
        }

        &:hover {
            border-color: white;
            box-shadow: 0 0 5px 2px white;
        }

        &:focus {
            outline: 0;
        }
    }
    .fontColorWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        align-items: center;
    }

    .fontColorChangeBtn {
        .form-button {
            width: 200px;
        }
    }

    .dropzone {
        &:focus {
            outline: 0;
        }
    }

}

@media (max-width: 575.98px) { 
    .settings-page {
        padding: 15px 10px;

        .general-setting {
            padding: 20px;
        }
    }
}
@media (min-width: 576px) and (max-width: 768px) {
    .settings-page {
        padding: 15px;

        .general-setting {
            padding: 20px;
        }
    }
}