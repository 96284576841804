.count-per-page {
    position: relative;
    align-items: center;

    .form-select {
        width: 80px;
        border-radius: 5px;
        border: 1px solid #767676;
        padding: 10px 10px 10px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        cursor: pointer;
    
        .label-span {
            font-weight: 500;
        }
    }

    .option-div {
        position: absolute;
        border: 1px solid #767676;
        border-radius: 5px;
        border-top: none;
        width: 80px;
        background-color: #5f5f5f;
        z-index: 8;
        cursor: pointer;

        .option-item {
            padding: 5px 15px;
            color: white;
            font-weight: 500;

            &:hover {
                background-color: grey;
                color: white;
            }

            &:first-child {
                border-radius: 5px 5px 0 0;
            }

            &:last-child {
                border: none;
                border-radius: 0 0 5px 5px;
            }
        }
    }

}

@media (max-width: 575.98px) { 
    .count-per-page {
        .form-select {
            width: 70px;        
        }
    
        .option-div {
            width: 70px;
        }
    }
}

@media (min-width: 576px) and (max-width: 768px) {
 
}