.sign-in-page {
    font-family: 'Work Sans', sans-serif;
    margin: auto;

    .login-form {
        padding: 50px;
        width: 500px;
        height: auto;
        background-color: #5f5f5f;
        border-radius: 10px;
        box-shadow: 3px 3px 20px black;
        text-align: center;

        .login-title {
            color: white;
            margin-bottom: 45px;
        }

        .ft-400 {
            font-weight: 400;
        }
    }
}

@media (max-width: 575.98px) { 
    .sign-in-page {

        .login-form {
            padding: 30px;
            width: 340px;
        }
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .sign-in-page {
        padding: 30px;
        .login-form {
            padding: 40px;
        }
    }
}