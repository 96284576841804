.row-item {
    .table-row {
        cursor: pointer;
        padding: 15px 0 15px 10px;
        flex-wrap: nowrap;
    
        &.expired {
             background-color: #912c2c !important;
           
           border: 4px solid #912c2c !important;
        } 
    
         .ft-18 {
            font-size: 18px;
        } 
    }

    .table-no-transfer {
        background-color: rgba(255, 255, 0, 0.2) !important;
        color: white;
    }
    
    .table-expired {
         text-align: center;
    
        &.expired {
            // background-color: #912c2c !important;
            color:  #670000;
         //   border: 4px solid #912c2c !important;
        }
    
        /* .ft-18 {
            font-size: 18px;
        } */
    }
    
    .ffl-db-page .table-row {
        padding: 15px 80px 15px 10px;
    }
    
    .ffl-row {
        padding: 15px 0 15px 10px;
        align-items: center;
        font-size: 14px;
        color: #ddd;
        background-color: #6a6a6a;
        border-top: 2px solid #555555;
    
        &.bottom-border {
            border-top: none;
            border-bottom: 1px solid #555555;
            background-color: #777777;
        }
    }
    
    .non-ffl-row {
        padding: 15px 0 15px 10px;
        margin: 0 -15px 0 -15px;
        color: #eeeeee;
        background-color: #777777;
        border-bottom: 1px solid #555555;
    }
    
    .dateWrapper {
    
        &.fflDate {
            .react-datepicker-wrapper {
                input {
                    width: 40px;
                }
            }
        }    
    }
    
    .full-size-img-wrapper {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        // background-color: rgba(255,255,255,0.6);
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 12;
        padding: 30px 50px;


        
    
        .full-size-img {
            background-color: #6f6f6f;
            border-radius: 20px;
            box-shadow: 0 0 14px 3px #de8686;
            // width: 100%;
            min-width: 50%;
            max-width: 90%;
            margin-top: 30px;
            height: 90%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            .flex_row{
                justify-content: center;
                display: flex;
                padding: 20px;
            }
    
            .closeIcon {
                position: inherit;
                align-self: end;
                font-size: 30px;
                text-shadow: 2px 1px white;
                cursor: pointer;
            }
    
            button {
                position: absolute;
                bottom: 20px;
                background-color: transparent;
                border-radius: 10px;
    
                &:focus {
                    outline: 0;
                }
    
                &.replace {
                    left:20px;
                    border: 2px solid #32a2a2;
                    color: #32a2a2;
                }
    
                &.delete {
                    right: 20px;
                    border: 2px solid rgb(21, 53, 156);
                    color: rgb(156, 21, 21);
                }
            }
    
            label {
                margin: 0 0px;
                color: black;
                cursor: pointer;
                font-size: 12px;           
    
                span {
                    font-size: 18px;                
                    color: grey;
                    text-decoration: underline;
                }
            }
            .content-col{
                .dropzone{
                    margin: 0 auto;
                    label{
                        span{
                            color: white;
                        }
                    }
                  
        
                }
                .checkIcon {
                    position: absolute;
                    bottom: 12px;
                    left: 30%;
                    color: #99ffee;
                  
                    cursor: pointer;
                    font-size: 22px;
    
                    &:hover {
                        font-weight: 800;
                    }
                }
                .removeIcon {
                    position: absolute;
                    bottom: 12px;
                    right:  30%;
                    color: #bd1919;
                    font-size: 22px;
                    cursor: pointer;
    
                    &:hover {
                        font-weight: 800;
                    }
                }
                .content-images{
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-gap: 10px;
                    grid-auto-rows: minmax(100px, auto);
                   
                   
                    
                }
               
                    .one {
                        grid-row: 1;
                        grid-column: 1 / span 3;
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        place-self: center;
                        img {
                            height: 50vh;
                            border-radius: 10px;
                            margin: 0 auto;
                            max-width: max-content;
                            min-width: max-content;
                        }
                        .image-gallery{
                            width: 100%;
                            height: 100%;
                            max-height: 502px;
                        } 
                    }
                    .one-mobile {
                        grid-row: 1;
                        grid-column: 1 / span 3;
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        place-self: center;
                        img {
                            height: 100%;
                            border-radius: 10px;
                            margin: 0 auto;
                            margin-top: 15%;
                            max-width: 70%;
                            min-width: 70%;
                        }
                        .image-gallery{
                            width: 100%;
                            height: 100%;
                            max-height: 502px;
                        } 
                    }
                    .two {
                    grid-column: 2;
                    grid-row: 2;
                    }
                    .three {
                    grid-column: 1;
                    grid-row: 2;
                    }
                    .four {
                    grid-column: 3;
                    grid-row: 2;
                    }
          

            }
         
            
            
              .photo-div{
                background-color: #6f6f6f;
                padding: 10px 14px;
                border-radius: 5px;
                
                &:first-of-type {
                    margin-bottom: 15px;
                }
        
                // &:last-of-type {
                //     min-height: calc(100% - 315px);
                // }
        
                .non-image {
                    width: 100%;
                    height: 280px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 2px dashed #a3a3a3;
                    color: #a3a3a3;
                    font-size: 24px;
                    font-weight: 700;
        
                    &.gallery {
                        height: 100px;
                    }
                }
        
                .active-img {
                    border-radius: 5px;
                    width: 100%;
                    height: calc(100% - 30px);
                }
                   .checkIcon {
                    position: absolute;
                    bottom: 12px;
                    left: 12px;
                    color: #99ffee;
                  
                    cursor: pointer;
                    font-size: 22px;
    
                    &:hover {
                        font-weight: 800;
                    }
                }
                .removeIcon {
                    position: absolute;
                    bottom: 12px;
                    right: 12px;
                    color: #bd1919;
                    font-size: 22px;
                    cursor: pointer;
    
                    &:hover {
                        font-weight: 800;
                    }
                }
    
        
                .gallery-item {
                    position: relative;
                    padding: 5px;
                    
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 3px;
                    }
        
                    .checkIcon {
                        position: absolute;
                        bottom: 12px;
                        left: 12px;
                        color: #99ffee;
                        visibility: hidden;
                        cursor: pointer;
                        font-size: 22px;
        
                        &:hover {
                            font-weight: 800;
                        }
                    }
                    .removeIcon {
                        position: absolute;
                        bottom: 12px;
                        right: 12px;
                        color: #bd1919;
                        font-size: 22px;
                        cursor: pointer;
                        visibility: hidden;
        
                        &:hover {
                            font-weight: 800;
                        }
                    }
        
                    &:hover {
                        img {
                            border-color: #99ffee;
                            box-shadow: 0 0 5px 3px #99ffee;
                        }                              
        
                        .checkIcon {
                            visibility: visible;
                        }
                        .removeIcon {
                            visibility: visible;
                        }
                    }
                }
        
                .add-gallery {
                    color: white;
                    font-size: 14px;
                    margin-top: 10px;
                    cursor: pointer;
        
                    span {
                        &:first-of-type {
                            text-decoration: underline;
                        }
        
                        &:last-of-type {
                            font-size: 9px;
                            color: #aaaaaa;
                        }
                    }
        
                }
                .gallery-item {
                    position: relative;
                    padding: 5px;
                    
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 3px;
                    }
        
                    .checkIcon {
                        position: absolute;
                        bottom: 12px;
                        left: 12px;
                        color: #99ffee;
                        visibility: hidden;
                        cursor: pointer;
                        font-size: 22px;
        
                        &:hover {
                            font-weight: 800;
                        }
                    }
                    .removeIcon {
                        position: absolute;
                        bottom: 12px;
                        right: 12px;
                        color: #bd1919;
                        font-size: 22px;
                        cursor: pointer;
                        visibility: hidden;
        
                        &:hover {
                            font-weight: 800;
                        }
                    }
        
                    &:hover {
                        img {
                            border-color: #99ffee;
                            box-shadow: 0 0 5px 3px #99ffee;
                        }                              
        
                        .checkIcon {
                            visibility: visible;
                        }
                        .removeIcon {
                            visibility: visible;
                        }
                    }
                }
        
                .add-gallery {
                    color: white;
                    font-size: 14px;
                    margin-top: 10px;
                    cursor: pointer;
        
                    span {
                        &:first-of-type {
                            text-decoration: underline;
                        }
        
                        &:last-of-type {
                            font-size: 9px;
                            color: #aaaaaa;
                        }
                    }
        
                }
                .photo-div {
                    background-color: #6f6f6f;
                    padding: 10px 14px;
                    border-radius: 5px;
                    
                    &:first-of-type {
                        margin-bottom: 15px;
                    }
            
                    // &:last-of-type {
                    //     min-height: calc(100% - 315px);
                    // }
            
                    .non-image {
                        width: 100%;
                        height: 280px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 2px dashed #a3a3a3;
                        color: #a3a3a3;
                        font-size: 24px;
                        font-weight: 700;
            
                        &.gallery {
                            height: 100px;
                        }
                    }
            
                    .active-img {
                        border-radius: 5px;
                        width: 100%;
                        height: calc(100% - 30px);
                    }
            
                    .gallery-item {
                        position: relative;
                        padding: 5px;
                        
                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 3px;
                        }
            
                        .checkIcon {
                            position: absolute;
                            bottom: 12px;
                            left: 12px;
                            color: #99ffee;
                            visibility: hidden;
                            cursor: pointer;
                            font-size: 22px;
            
                            &:hover {
                                font-weight: 800;
                            }
                        }
                        .removeIcon {
                            position: absolute;
                            bottom: 12px;
                            right: 12px;
                            color: #bd1919;
                            font-size: 22px;
                            cursor: pointer;
                            visibility: hidden;
            
                            &:hover {
                                font-weight: 800;
                            }
                        }
            
                        &:hover {
                            img {
                                border-color: #99ffee;
                                box-shadow: 0 0 5px 3px #99ffee;
                            }                              
            
                            .checkIcon {
                                visibility: visible;
                            }
                            .removeIcon {
                                visibility: visible;
                            }
                        }
                    }
            
                    .add-gallery {
                        color: white;
                        font-size: 14px;
                        margin-top: 10px;
                        cursor: pointer;
            
                        span {
                            &:first-of-type {
                                text-decoration: underline;
                            }
            
                            &:last-of-type {
                                font-size: 9px;
                                color: #aaaaaa;
                            }
                        }
            
                    }
                }
            } 
        }

     
   
    }

    
    

}



@media (max-width: 575.98px) { 

    
    .ffl-row {
        padding: 10px 0 10px 0px;
        font-size: 12px;
    }
        
    .full-size-img-wrapper {
        padding: 20px 10px;

        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        // background-color: rgba(255,255,255,0.6);
        background-color: rgba(232, 32, 32, 0.4);
        z-index: 12;
        padding: 30px 50px;

            
        .full-size-img {
            min-width: 94%;
            max-width: 94%;
            height: 50%;
            margin-top: 0px;

            .closeIcon {
                top: 10px;
                right: 10px;
                font-size: 20px;
            }

            button {              
                bottom: 10px;            

                &.replace {
                    left:10px;                  
                }

                &.delete {
                    right: 10px;
                }
            }

            label {
                margin: 0 20px;
            }
        }
    }
}

@media (min-width: 400px) and (max-width: 769px) {

    
    .full-size-img-wrapper {
        padding: 20px 10px;

        padding: 20px 10px;

        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        // background-color: rgba(255,255,255,0.6);
        background-color: rgba(232, 32, 32, 0.4);
        z-index: 12;
        padding: 30px 50px;

        .full-size-img {

            .closeIcon {
                top: 10px;
                right: 10px;
                font-size: 20px;
            }

            button {              
                bottom: 10px;            

                &.replace {
                    left:10px;                  
                }

                &.delete {
                    right: 10px;
                }
            }

            label {
                margin: 0 20px;
            }
        }
    }
}