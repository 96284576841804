.ungroupBtn {
    background-color: #717171;
    color: white;
    font-size: 12px;
    padding: 4px 8px;
    border: 1.2px solid #a3a3a3;
    border-radius: 30px;

    &:focus {
        outline: 0;
    }

    &:hover {            
        border-color: white;
        background-color: #a3a3a3;
    }
}

.trackingWrapper {
    display: flex;
    input {
        background-color: transparent;
        border: 1px solid #a3a3a3;
        border-right: none;
        height: 35px;
        width: calc(100% - 80px);
        border-radius: 5px 0 0 5px;
        color: #a3a3a3;
        padding: 0 5px;
    
        &:focus {
            outline: 0;
        }

        &.invalid {
            border: 1px solid red;
            box-shadow: -1px 0px 10px red;
        }
    }
    
    button {
        background-color: #717171 !important;
        color: #a3a3a3 !important;
        font-size: 14px !important;
        border: 1px solid #a3a3a3 !important;
        border-radius: 0 5px 5px 0 !important;
        height: 35px !important;
        padding: 4px 7px !important;
    
        &:hover {
            color: white !important;
            border-color: white !important;
            background-color: #a3a3a3 !important;
        }
    }
}

.table-row {
    cursor: inherit; 
   
    .form-select-container .form-select 
{
    background-color: rgba(0, 0, 0, 0.1);

    .label-span {
        color: #cccccc;
    }
}

}

.fflNameLabel {
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
    font-size: 14px;
    padding: 4px 8px;
    border: 1.2px solid #a3a3a3;
    border-radius: 30px;
    cursor: pointer;

    &.mobile {
        font-size: 12px;
        padding: 2px 4px;
        border-width: 1px;
    }
}

.mobile-child-wrapper {

    .table-row {
        border-left: 1px solid white;
        border-right: 1px solid white;

        &:first-of-type {
            border-top: 1px solid white;
        }

        &:last-of-type {
            border-bottom: 1px solid white;
        }
    }
}
.ffl-row{
    display: flex;
    flex-wrap: nowrap;
}
