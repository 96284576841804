
.text-center {
    text-align: center;
}
.edit {
    cursor: pointer;
    text-align: center;
    margin: 0 auto;
}

.trunc {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ungroupBtn {
    background-color: #717171;
    color: white;
    font-size: 12px;
    padding: 4px 8px;
    border: 1.2px solid #a3a3a3;
    border-radius: 30px;

    &:focus {
        outline: 0;
    }

    &:hover {            
        border-color: white;
        background-color: #a3a3a3;
    }
}

.trackingWrapper {
    display: flex;
    input {
        background-color: transparent;
        border: 1px solid #a3a3a3;
        border-right: none;
        height: 35px;
        width: calc(100% - 80px);
        border-radius: 5px 0 0 5px;
        color: #a3a3a3;
        padding: 0 5px;
    
        &:focus {
            outline: 0;
        }

        &.invalid {
            border: 1px solid red;
            box-shadow: -1px 0px 10px red;
        }
    }
    
    button {
        background-color: #717171 !important;
        color: #a3a3a3 !important;
        font-size: 14px !important;
        border: 1px solid #a3a3a3 !important;
        border-radius: 0 5px 5px 0 !important;
        height: 35px !important;
        padding: 4px 7px !important;
    
        &:hover {
            color: white !important;
            border-color: white !important;
            background-color: #a3a3a3 !important;
        }
    }
}

.table-row {
    cursor: inherit;
    
    .form-select-container .form-select 
    {
        background-color: rgba(0, 0, 0, 0.1);

        .label-span {
            color: #cccccc;
        }
    }
    .far{
        cursor: pointer;
        &:hover{
            color: white;
        }
    }
}

.fflNameLabel {
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
    font-size: 14px;
    padding: 4px 8px;
    border: 1.2px solid #a3a3a3;
    border-radius: 30px;
    cursor: pointer;

    &.mobile {
        font-size: 12px;
        padding: 2px 4px;
        border-width: 1px;
    }
}

.mobile-child-wrapper {

    .table-row {
        border-left: 1px solid white;
        border-right: 1px solid white;

        &:first-of-type {
            border-top: 1px solid white;
        }

        &:last-of-type {
            border-bottom: 1px solid white;
        }
    }
}
.ffl-row{
    display: flex;
    flex-wrap: nowrap;
}


.button-state-style{

    border-left-width: 8px !important;
    border-left-style: solid !important;
    border-left-color: white ;
    border-bottom: none !important;

    &-thin{
        border-left-width: 3px !important;

    }

    &-white{
        border-left-color: white !important;
    }
    &-green{
        border-left-color: rgb(99,173,48) !important;
    }
    &-yellow{
        border-left-color: rgb(211,172,0) !important;
    }
    
}


.container-inner-data{
    padding-left: 10px;
    margin-left: -15px;
    width: calc(100% + 15px);
    border-left-width: 8px ;
    border-left-style: solid;
    
    &-left-white{
        border-left-color: white !important;
        background-color: white;
    }
    &-left-green{
        border-left-color: rgb(99,173,48) !important;
        background-color: rgb(99,173,48);
    }
    &-left-yellow{
        border-left-color: rgb(211,172,0) !important;
        background-color: rgb(211,172,0);
    }
    
    &-top{
        &-white{
            & > .complete-webinar-row{
                border: 1px solid white;
                border-left: 3px solid white;
                border-right: 5px solid white;
                border-bottom: none;
            }
        }
        &-yellow{
            & > .complete-webinar-row{
                border: 1px solid rgb(211,172,0);
                border-left: 3px solid rgb(211,172,0);
                border-bottom: none;
            }
        }
        &-green{
            & > .complete-webinar-row{
                border: 1px solid  rgb(99,173,48);
                border-left: 3px solid rgb(99,173,48);
                border-bottom: none;
            }
        }
    }
    &-footer{
        &-white{
            & > .complete-webinar-row{
                border: 1px solid white;
                border-left: 3px solid white;
                border-top: none;
                border-right: 5px solid white;
            }
        }
        &-yellow{
            & > .complete-webinar-row{
                border: 1px solid rgb(211,172,0);
                border-left: 3px solid rgb(211,172,0);
                border-top: none;
                border-right: 5px solid rgb(211,172,0);
            }
        }
        &-green{
            & > .complete-webinar-row{
                border: 1px solid rgb(99,173,48);
                border-left: 3px solid rgb(99,173,48);
                border-top: none;
                border-right: 5px solid rgb(99,173,48);
            }
        }
        
        
    }    
}

.custom-button{
    background-color: transparent !important;
}

.selected-row{
    border-right-width: 6px !important;
    border-top-width: 6px !important;
    border-right-style: solid !important;
    border-top-style: solid !important;

    &-int{
        border-right-width: 6px !important;
        border-top-width: 6px !important;
        border-right-style: solid !important;
        border-top-style: solid !important;
        
        &-white{
            border-right-color: white  !important;
        }
        &-green{
            border-right-color: rgb(99,173,48)  !important;
        }
        &-yellow{
            border-right-color: rgb(211,172,0)  !important;
        }
    }
    
    &-white{
        border-right-color: white  !important;
        border-top-color:  white  !important;
    }
    &-green{
        border-right-color: rgb(99,173,48)  !important;
        border-top-color:  rgb(99,173,48)  !important;
    }
    &-yellow{
        border-right-color: rgb(211,172,0)  !important;
        border-top-color:  rgb(211,172,0)  !important;
    }
}


.container-initial-row-item{
    position: relative;

    &::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: -10px;
        width: calc(100% + 20px);
        height: 6px;
    }

    &-white{
        &::after{
            background-color: white;
        }
    }
    &-green{
        &::after{
            background-color: rgb(99,173,48);
        }
    }
    &-yellow{
        &::after{
            background-color: rgb(211,172,0);
        }
    }

    
}


.modal-shipping {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 300px;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    z-Index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content{
        width: auto;
        min-width: 300px;
        height: auto;
        padding: 20px;
        border-radius: 7px;
        overflow-x: hidden;
        background-color: rgba(0,0,0,0.8);
        display: flex;
        flex-direction: column;
        justify-content: center;

        & >h2.title{
            font-size: 16px;
            align-self: center;
            color: rgba(255,255,255,0.7);
        }

        & > .buttons{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            & > h2.title{
                font-size: 18px;
            }

            & > .btns {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 10px;
            }
        }
    }
}

.content-ffl-data{
    width: 80%;
    height: 90%;
    max-height: 90%;
    overflow: hidden;
    overflow-y: scroll;
    padding: 20px;
    background-color: #6f6f6f;
    border-radius: 20px;
    box-shadow: 0 0 14px 3px #de8686;
    min-width: 50%;
    max-width: 90%;

    .flex_row{
        justify-content: center;
        display: flex;
        padding: 20px;
    }

    .closeIcon {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 30px;
        text-shadow: 2px 1px white;
        cursor: pointer;
    }

    button {
        position: absolute;
        bottom: 20px;
        background-color: transparent;
        border-radius: 10px;

        &:focus {
            outline: 0;
        }

        &.replace {
            left:20px;
            border: 2px solid #32a2a2;
            color: #32a2a2;
        }

        &.delete {
            right: 20px;
            border: 2px solid rgb(21, 53, 156);
            color: rgb(156, 21, 21);
        }
    }

    label {
        margin: 0 0px;
        color: black;
        cursor: pointer;
        font-size: 12px;           

        span {
            font-size: 18px;                
            color: grey;
            text-decoration: underline;
        }
    }
   .col-2{
    img {
                
        height: 100%;
        border-radius: 10px;
        margin: 0 auto;
        min-width: 70%;
        max-width: 70%;
     
       
    }
    .image-gallery{
        margin: 0 auto;
        min-width: 70%;
        max-width: 70%;
    }
   }
 
    
    
    
      .photo-div{
        background-color: #6f6f6f;
        padding: 10px 14px;
        border-radius: 5px;
        
        &:first-of-type {
            margin-bottom: 15px;
        }

        // &:last-of-type {
        //     min-height: calc(100% - 315px);
        // }

        .non-image {
            width: 100%;
            height: 280px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px dashed #a3a3a3;
            color: #a3a3a3;
            font-size: 24px;
            font-weight: 700;

            &.gallery {
                height: 100px;
            }
        }

        .active-img {
            border-radius: 5px;
            width: 100%;
            height: calc(100% - 30px);
        }
           .checkIcon {
            position: absolute;
            bottom: 12px;
            left: 12px;
            color: #99ffee;
          
            cursor: pointer;
            font-size: 22px;

            &:hover {
                font-weight: 800;
            }
        }
        .removeIcon {
            position: absolute;
            bottom: 12px;
            right: 12px;
            color: #bd1919;
            font-size: 22px;
            cursor: pointer;

            &:hover {
                font-weight: 800;
            }
        }


        .gallery-item {
            position: relative;
            padding: 5px;
            
            img {
                width: 100%;
                height: 100%;
                border-radius: 3px;
            }

            .checkIcon {
                position: absolute;
                bottom: 12px;
                left: 12px;
                color: #99ffee;
                visibility: hidden;
                cursor: pointer;
                font-size: 22px;

                &:hover {
                    font-weight: 800;
                }
            }
            .removeIcon {
                position: absolute;
                bottom: 12px;
                right: 12px;
                color: #bd1919;
                font-size: 22px;
                cursor: pointer;
                visibility: hidden;

                &:hover {
                    font-weight: 800;
                }
            }

            &:hover {
                img {
                    border-color: #99ffee;
                    box-shadow: 0 0 5px 3px #99ffee;
                }                              

                .checkIcon {
                    visibility: visible;
                }
                .removeIcon {
                    visibility: visible;
                }
            }
        }

        .add-gallery {
            color: white;
            font-size: 14px;
            margin-top: 10px;
            cursor: pointer;

            span {
                &:first-of-type {
                    text-decoration: underline;
                }

                &:last-of-type {
                    font-size: 9px;
                    color: #aaaaaa;
                }
            }

        }
        .gallery-item {
            position: relative;
            padding: 5px;
            
            img {
                width: 100%;
                height: 100%;
                border-radius: 3px;
            }

            .checkIcon {
                position: absolute;
                bottom: 12px;
                left: 12px;
                color: #99ffee;
                visibility: hidden;
                cursor: pointer;
                font-size: 22px;

                &:hover {
                    font-weight: 800;
                }
            }
            .removeIcon {
                position: absolute;
                bottom: 12px;
                right: 12px;
                color: #bd1919;
                font-size: 22px;
                cursor: pointer;
                visibility: hidden;

                &:hover {
                    font-weight: 800;
                }
            }

            &:hover {
                img {
                    border-color: #99ffee;
                    box-shadow: 0 0 5px 3px #99ffee;
                }                              

                .checkIcon {
                    visibility: visible;
                }
                .removeIcon {
                    visibility: visible;
                }
            }
        }

        .add-gallery {
            color: white;
            font-size: 14px;
            margin-top: 10px;
            cursor: pointer;

            span {
                &:first-of-type {
                    text-decoration: underline;
                }

                &:last-of-type {
                    font-size: 9px;
                    color: #aaaaaa;
                }
            }

        }
        .photo-div {
            background-color: #6f6f6f;
            padding: 10px 14px;
            border-radius: 5px;
            
            &:first-of-type {
                margin-bottom: 15px;
            }
    
            // &:last-of-type {
            //     min-height: calc(100% - 315px);
            // }
    
            .non-image {
                width: 100%;
                height: 280px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 2px dashed #a3a3a3;
                color: #a3a3a3;
                font-size: 24px;
                font-weight: 700;
    
                &.gallery {
                    height: 100px;
                }
            }
    
            .active-img {
                border-radius: 5px;
                width: 100%;
                height: calc(100% - 30px);
            }
    
            .gallery-item {
                position: relative;
                padding: 5px;
                
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 3px;
                }
    
                .checkIcon {
                    position: absolute;
                    bottom: 12px;
                    left: 12px;
                    color: #99ffee;
                    visibility: hidden;
                    cursor: pointer;
                    font-size: 22px;
    
                    &:hover {
                        font-weight: 800;
                    }
                }
                .removeIcon {
                    position: absolute;
                    bottom: 12px;
                    right: 12px;
                    color: #bd1919;
                    font-size: 22px;
                    cursor: pointer;
                    visibility: hidden;
    
                    &:hover {
                        font-weight: 800;
                    }
                }
    
                &:hover {
                    img {
                        border-color: #99ffee;
                        box-shadow: 0 0 5px 3px #99ffee;
                    }                              
    
                    .checkIcon {
                        visibility: visible;
                    }
                    .removeIcon {
                        visibility: visible;
                    }
                }
            }
    
            .add-gallery {
                color: white;
                font-size: 14px;
                margin-top: 10px;
                cursor: pointer;
    
                span {
                    &:first-of-type {
                        text-decoration: underline;
                    }
    
                    &:last-of-type {
                        font-size: 9px;
                        color: #aaaaaa;
                    }
                }
    
            }
        }
    } 
}

.full-size-img-wrapper {
    
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50px;
    left: 0;
    width: 100%;
    height: 100vh;
    // background-color: rgba(255,255,255,0.6);
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 12;
    padding: 30px 50px;

    .full-size-img2 {
        background-color: #393939;
        border-radius: 20px;
        box-shadow: 0 0 14px 3px #de8686;
        // width: 100%;
        min-width: 50%;
        max-width: 90%;
        margin-top: 30px;
        height: 90%;
        position: relative;
        justify-content: center;
        align-items: center;
    
    }
    .full-size-img {
        background-color: #6f6f6f;
        border-radius: 20px;
        box-shadow: 0 0 14px 3px #de8686;
        // width: 100%;
        min-width: 50%;
        max-width: 60%;
        margin-top: 30px;
        height: 90%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        overflow-y: scroll;

        .flex_row{
            justify-content: center;
            display: flex;
            padding: 20px;
        }

        .closeIcon {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 30px;
            text-shadow: 2px 1px white;
            cursor: pointer;
        }

        button {
            position: absolute;
            bottom: 20px;
            background-color: transparent;
            border-radius: 10px;

            &:focus {
                outline: 0;
            }

            &.replace {
                left:20px;
                border: 2px solid #32a2a2;
                color: #32a2a2;
            }

            &.delete {
                right: 20px;
                border: 2px solid rgb(21, 53, 156);
                color: rgb(156, 21, 21);
            }
        }

        label {
            margin: 0 0px;
            color: black;
            cursor: pointer;
            font-size: 12px;           

            span {
                font-size: 18px;                
                color: grey;
                text-decoration: underline;
            }
        }
       .col-2{
        img {
                    
            height: 100%;
            border-radius: 10px;
            margin: 0 auto;
            min-width: 70%;
            max-width: 70%;
         
           
        }
        .image-gallery{
            margin: 0 auto;
            min-width: 70%;
            max-width: 70%;
        }
       }
     
        
        
        
          .photo-div{
            background-color: #6f6f6f;
            padding: 10px 14px;
            border-radius: 5px;
            
            &:first-of-type {
                margin-bottom: 15px;
            }
    
            // &:last-of-type {
            //     min-height: calc(100% - 315px);
            // }
    
            .non-image {
                width: 100%;
                height: 280px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 2px dashed #a3a3a3;
                color: #a3a3a3;
                font-size: 24px;
                font-weight: 700;
    
                &.gallery {
                    height: 100px;
                }
            }
    
            .active-img {
                border-radius: 5px;
                width: 100%;
                height: calc(100% - 30px);
            }
               .checkIcon {
                position: absolute;
                bottom: 12px;
                left: 12px;
                color: #99ffee;
              
                cursor: pointer;
                font-size: 22px;

                &:hover {
                    font-weight: 800;
                }
            }
            .removeIcon {
                position: absolute;
                bottom: 12px;
                right: 12px;
                color: #bd1919;
                font-size: 22px;
                cursor: pointer;

                &:hover {
                    font-weight: 800;
                }
            }

    
            .gallery-item {
                position: relative;
                padding: 5px;
                
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 3px;
                }
    
                .checkIcon {
                    position: absolute;
                    bottom: 12px;
                    left: 12px;
                    color: #99ffee;
                    visibility: hidden;
                    cursor: pointer;
                    font-size: 22px;
    
                    &:hover {
                        font-weight: 800;
                    }
                }
                .removeIcon {
                    position: absolute;
                    bottom: 12px;
                    right: 12px;
                    color: #bd1919;
                    font-size: 22px;
                    cursor: pointer;
                    visibility: hidden;
    
                    &:hover {
                        font-weight: 800;
                    }
                }
    
                &:hover {
                    img {
                        border-color: #99ffee;
                        box-shadow: 0 0 5px 3px #99ffee;
                    }                              
    
                    .checkIcon {
                        visibility: visible;
                    }
                    .removeIcon {
                        visibility: visible;
                    }
                }
            }
    
            .add-gallery {
                color: white;
                font-size: 14px;
                margin-top: 10px;
                cursor: pointer;
    
                span {
                    &:first-of-type {
                        text-decoration: underline;
                    }
    
                    &:last-of-type {
                        font-size: 9px;
                        color: #aaaaaa;
                    }
                }
    
            }
            .gallery-item {
                position: relative;
                padding: 5px;
                
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 3px;
                }
    
                .checkIcon {
                    position: absolute;
                    bottom: 12px;
                    left: 12px;
                    color: #99ffee;
                    visibility: hidden;
                    cursor: pointer;
                    font-size: 22px;
    
                    &:hover {
                        font-weight: 800;
                    }
                }
                .removeIcon {
                    position: absolute;
                    bottom: 12px;
                    right: 12px;
                    color: #bd1919;
                    font-size: 22px;
                    cursor: pointer;
                    visibility: hidden;
    
                    &:hover {
                        font-weight: 800;
                    }
                }
    
                &:hover {
                    img {
                        border-color: #99ffee;
                        box-shadow: 0 0 5px 3px #99ffee;
                    }                              
    
                    .checkIcon {
                        visibility: visible;
                    }
                    .removeIcon {
                        visibility: visible;
                    }
                }
            }
    
            .add-gallery {
                color: white;
                font-size: 14px;
                margin-top: 10px;
                cursor: pointer;
    
                span {
                    &:first-of-type {
                        text-decoration: underline;
                    }
    
                    &:last-of-type {
                        font-size: 9px;
                        color: #aaaaaa;
                    }
                }
    
            }
            .photo-div {
                background-color: #6f6f6f;
                padding: 10px 14px;
                border-radius: 5px;
                
                &:first-of-type {
                    margin-bottom: 15px;
                }
        
                // &:last-of-type {
                //     min-height: calc(100% - 315px);
                // }
        
                .non-image {
                    width: 100%;
                    height: 280px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 2px dashed #a3a3a3;
                    color: #a3a3a3;
                    font-size: 24px;
                    font-weight: 700;
        
                    &.gallery {
                        height: 100px;
                    }
                }
        
                .active-img {
                    border-radius: 5px;
                    width: 100%;
                    height: calc(100% - 30px);
                }
        
                .gallery-item {
                    position: relative;
                    padding: 5px;
                    
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 3px;
                    }
        
                    .checkIcon {
                        position: absolute;
                        bottom: 12px;
                        left: 12px;
                        color: #99ffee;
                        visibility: hidden;
                        cursor: pointer;
                        font-size: 22px;
        
                        &:hover {
                            font-weight: 800;
                        }
                    }
                    .removeIcon {
                        position: absolute;
                        bottom: 12px;
                        right: 12px;
                        color: #bd1919;
                        font-size: 22px;
                        cursor: pointer;
                        visibility: hidden;
        
                        &:hover {
                            font-weight: 800;
                        }
                    }
        
                    &:hover {
                        img {
                            border-color: #99ffee;
                            box-shadow: 0 0 5px 3px #99ffee;
                        }                              
        
                        .checkIcon {
                            visibility: visible;
                        }
                        .removeIcon {
                            visibility: visible;
                        }
                    }
                }
        
                .add-gallery {
                    color: white;
                    font-size: 14px;
                    margin-top: 10px;
                    cursor: pointer;
        
                    span {
                        &:first-of-type {
                            text-decoration: underline;
                        }
        
                        &:last-of-type {
                            font-size: 9px;
                            color: #aaaaaa;
                        }
                    }
        
                }
            }
        } 
    }

 

}

.toggle {
    opacity: 1 !important;
}
