.table-row {            
     padding: 15px 0 15px 10px;
     align-items: center;
     font-size: 13px;
     color: #a3a3a3;  
     
     &:nth-child(even){
         background-color: #666666;
     }
 
     .thumb {
         // margin: 0 40px 0 -50px;
         img {
             width: 50px;
             height: 48px;
             border-radius: 3px;
         }
     }
 
     .non-img {
         display: flex;
         justify-content: center;
         align-items: center;
         font-size: 24px;
         color: #a3a3a3;
     }
 
     .fas {
         cursor: pointer;
 
         &:hover {
             color: white;
         }
     }
 
     .calIcon {
         cursor: pointer;
 
         &:hover {
             color: white;
         }
     }
 
     // .activeBtn {
     //     background-color: #717171;
     //     color: white;
     //     font-size: 12px;
     //     padding: 4px 8px;
     //     border: 1.2px solid #a3a3a3;
     //     border-radius: 30px;
 
     //     &:focus {
     //         outline: 0;
     //     }
 
     //     &:hover {
     //         border-color: white;
     //         background-color: #a3a3a3;
     //     }
     // }
 
     .react-datepicker-wrapper {
         input {
             width: 130px;
             background-color: transparent;
             border: none;
             color: #a3a3a3;
 
             &:focus {
                 outline: 0;
             }
         }
     }
 
     .react-datepicker-popper {
         left: -165px !important;
 
         .react-datepicker__triangle {
             left: 220px !important;
         }
     }
     .dateWrapper {
         display: flex;
         justify-content: center;
         align-items: center;
     }
     .openBtn {
         background-color: transparent !important;
         border: 1.5px solid #eeeeee !important;
         border-radius: 50% !important;
         width: 26px !important;
         height: 26px !important;
         color: #eeeeee !important;
         font-size: 16px !important;
         padding: 0 !important;
     
         &:focus {
             outline: 0;
         }
     }
 }
 
.removeDialogWrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
    
    .removeDialog {
        padding: 20px 20px 30px 20px;
        width: 500px;
        border-radius: 10px;
        background-color:grey;
        box-shadow: 0 0 10px 2px white;

        .closeIcon {
            cursor: pointer;
        }

        .custom-datepicker {
            width: 100%;
            background-color: #777777;
            border-radius: 5px;
            color: #a3a3a3;
        
            .datepicker-header {
                border: 1px solid lightgray;
                border-radius: 5px;
                padding: 10px 15px;
            }
        
            .date-select {
                display: flex;
                justify-content: space-between;
                padding: 0 70px;
        
                p {
                    cursor: context-menu;
        
                    &.pointer {
                        cursor: pointer;
                    }
                }
            }
        
            .form-button {
                background-color: #8c8c8c;
            }
        
        }

        .emailList {
            word-break: break-all;
        }
    }    
}


@media (max-width: 575.98px) { 
    
    .removeDialogWrapper {

        .removeDialog {
            padding: 10px;
            width: 92%;

            h2 {
                font-size: 20px;
                margin-bottom: 10px !important;
                margin-top: -15px !important;
            }

            .mobile-row {
                margin-bottom: 10px !important;

                .col {
                    padding: 0 10px;
                }

                .form-button {
                    height: 40px;
                    font-size: 12px;
                    // padding: 2px 8px;
                }
            }

            .custom-datepicker {
                width: 100%;
                background-color: #777777;
                border-radius: 5px;
                color: #a3a3a3;
            
                .datepicker-header {
                    border: 1px solid lightgray;
                    border-radius: 5px;
                    padding: 10px 15px;
                }
            
                .date-select {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 70px;
            
                    p {
                        cursor: context-menu;
            
                        &.pointer {
                            cursor: pointer;
                        }
                    }
                }
            
                .form-button {
                    background-color: #8c8c8c;
                }
            
            }
        }    
    }

}

 
 
 @media (max-width: 768px) {
 
     .mobile-opend-table {
         background-color: #565656 !important;
     }
 }
 
 
 