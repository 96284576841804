$sub-color: #a0a0a0;
$main-color: black;

@mixin shrinkLabel {
    top: -17px;
    font-size: 13px;
    color: white;    
}

.group {
    position: relative;
    margin: 30px 0;
    font-family: 'PT Sans', sans-serif;

    .form-input {
        background: none;
        background-color: transparent;
        color: $sub-color;
        font-size: 20px;
        padding: 10px 10px 10px 30px;
        display: block;
        width: 100%;
        border: 1.5px solid #a3a3a3;
        border-radius: 30px;
        margin: 25px 0;

        &.red-outline {
            border-width: 1px;
            box-shadow: 0 0 5px 3px #ff3547;
        }

        &:focus {
            outline: none;
        }

        &:focus ~ .form-input-label {
            @include shrinkLabel();
        }
    }

    input[ type = 'password'] {
        letter-spacing: 0.3em;
    }

    .form-input-label { 
        color: $sub-color;
        font-size: 18px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 25px;
        top: 13px;
        transition: 300ms ease all;

        &.red-label {
            color: #ff3547 !important;
        }

        &.shrink {
            @include shrinkLabel();
        }
    }
}