.report-page {
    width: 100%;
    background-image: url('../../assets/imgs/background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 25px;
    font-family: "Work Sans", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;

    .report-wrapper {
        width: 600px;

        h1 {
            margin-bottom: 30px;
        }

        h5 {
            color: #a3a3a3;
        }

        .background-date-wrapper {
            border-radius: 30px;
            padding: 0 18px;
            border: 1.5px solid grey;
            color: #a3a3a3;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 50px;
            margin-bottom: 20px;

            .react-datepicker-wrapper {
                input {
                    width: 100%;
                    background-color: transparent;
                    border: none;
                    color: #a3a3a3;
                    border-right: 1px solid grey;
        
                    &:focus {
                        outline: 0;
                    }
                }
            }

            .datePickerIcon {
                padding-left: 10px;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 575.98px) {
    .report-page {
        .report-wrapper {
            width: auto;
        }
    }
}

@media (min-width: 575.98px) and (max-width: 769px) {
    .report-page {
        .report-wrapper {
            width: auto;
        }
    }
}