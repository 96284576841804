
.user-edit-icon {
    font-size: 17px;

    &:hover {
        color: #52995c;          
    }
}

.user-ban-icon {
    font-size: 17px;   

    &:hover {
        color: #333333;           
    }    
}

.user-unban-icon {
    font-size: 17px;   

    &:hover {
        color: #1dc9c9;           
    }    
}

.user-del-icon {
    font-size: 17px;    

    &:hover {
        color: #990000;            
    }     
}

.no-border-top {
    border-top: none;
}

