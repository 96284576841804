.wrapper {
    position: absolute;
    top: -30px;
    left: 20px;

    .triangleDiv {
        width: 0;
        height: 0;
        margin-left: 20px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid darkgray;
    }

    .passPolicyDiv {
        background-color: darkgray;
        border-radius: 5px;
        padding: 0 0 10px 0;
        color: white;

        .closeIconDiv {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid white;
            padding-right: 15px;

            .closeBtn {
                cursor: pointer;
            }
        }

        p {
            margin: 0;
            padding: 5px 15px;
        }

        ul {
            padding: 0 40px;
        }
    }
}