.ffl-row {
    border-bottom: 1px solid #737373;
}

.css-yk16xz-control {
    background-color: transparent !important;
    color: #a3a3a3 !important;
    border: 1px solid grey !important;
}

.css-1uccc91-singleValue {
    color: #a3a3a3 !important;
}

.css-1wa3eu0-placeholder {
    color: #a3a3a3 !important;
}

.css-1okebmr-indicatorSeparator {
    background-color: transparent !important;
}

.wrapped{
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}