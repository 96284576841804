@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

.App {
  background-color: grey;
  min-height: 100vh;

  .main-section {
    min-height: calc(100vh - 70px - 42px);
    background-image: url('./assets/imgs/background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: stretch;
  }

  .wait-loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(250, 250, 250, 0.6);
    z-index: 100;
    
    .loadIcon {
        position: absolute;
        left: 48%;
        top: 48%;
        // transform: translate(-50%,-50%);
        color:rgba(0, 0, 0, 0.6);
        font-size: 50px;
        margin-right: 7px;
        animation: spin 1s infinite linear;
    }
  }
}
//for removing google autocomplete logo 
.pac-container:after{
  content:none !important;
}

.ft19 {
  font-size: 19px;
  text-shadow: 3px 3px 2px black;
}

.ft43 {
  font-size: 43px;
  text-shadow: 3px 3px 2px black;
}

.ft35 {
  font-size: 35px;
  text-shadow: 3px 3px 2px black;
}

.show-more-button{
  color: lightgray !important;
}

@media (max-width: 500px) {
  .ft19 {
    font-size: 16px;
    text-shadow: 2px 2px 2px black;
  }
}
