.addNewProduct {
    padding: 30px 120px 80px 120px;

    .formCol {
        padding: 0 10px;
    }

    .form-select-container {
        font-family: 'PT Sans', sans-serif;
        margin: 10px 0;

        .form-select {
            border-width: 1.2px;
        }

        .option-div {
            background-color: #535353;
        }
    }

    .group {
        margin: 10px 0;

        .form-input {
            margin: 0;
            height: 50px;
        }
    }

    .form-button {
        margin: 20px 0;
    }

    textarea {
        overflow: auto;
        resize: none;
        width: 100%;
        border: 1.2px solid #a3a3a3;
        border-radius: 20px;
        padding: 16px 22px;
        max-height: 300px;
        color: #a3a3a3;
        background-color: transparent;
        margin: 10px 0;
        -ms-overflow-style: none;

        &.invalid {
            border-width: 1px;
            box-shadow: 0 0 5px 3px #ff3547;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        &:focus {
            outline: 0;
        }

        &::placeholder {
            color: #a3a3a3;
        }

        &::-webkit-input-placeholder {
            color: #a3a3a3;
        }
        
        &::-moz-placeholder {  /* Firefox 19+ */
            color: #a3a3a3;
        }
        
        &:-ms-input-placeholder {
            color: #a3a3a3;  
        }
    }

    .thumbImg {
        display: inline-flex;
        border-radius: 2px;
        margin-bottom: 8px;
        margin-right: 8px;
        border: 1px solid #eaeaea;
        width: 80px;
        height: 80px;
        padding: 4px;
        box-sizing: border-box;
        position: relative;

        .checkIcon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px;
            color: #99ffee;
            visibility: hidden;
        }

        &:hover {
            border-color: #99ffee;
            box-shadow: 0 0 5px #99ffee;

            .checkIcon {
                visibility: visible;
            }
        }
    }

    .dragBorder {
        display: flex;
        align-items: center;
        width: 100%;
        height: 120px;
        padding: 7px;
        border-radius: 40px;
        border: 2px dashed #a3a3a3;
        cursor: pointer;

        .dragWrapper {
            background-color: #505050;
            padding: 30px;
            width: 100%;
            height: 100%;
            border-radius: 30px;

            p {
                color: #a3a3a3;
                margin-bottom: 0;
            }
        }

        .activeImg {
            margin-left: 5px;
            border-radius: 24px;
            padding: 3px;
            border: 1.2px solid #99ffee;
            width: 120px;
            height: 100px;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                border-radius: 20px;
            }

            label {
                color: #99ffee;
                position: absolute;
                bottom: 5%;
                left: 50%;
                transform: translate(-50%);
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    .custom-datepicker {
        width: 100%;
        height: 400px;
        background-color: #777777;
        border-radius: 5px;
        color: #a3a3a3;
    
        .datepicker-header {
            border: 1px solid lightgray;
            border-radius: 5px;
            padding: 10px 15px;
        }
    
        .date-select {
            display: flex;
            justify-content: space-between;
            padding: 0 70px;
    
            p {
                cursor: context-menu;
    
                &.pointer {
                    cursor: pointer;
                }
            }
        }
    
        .form-button {
            background-color: #8c8c8c;
        }
    
    }
    
    .taxable-wrapper {
        padding: 10px;
        display: flex;
        // justify-content: space-between;
        align-items: center;

        p {
            color: #a3a3a3;
            padding: 0;
            margin: 0 10px 0 0;
            font-size: 18px;
            font-family: "PT Sans", sans-serif;;
        }

        .react-switch-bg {
            height: 34px !important;
            padding: 3px !important;
            width: 64px !important;
            border-radius: 30px !important;
    
            div:nth-of-type(2) {
                top: 2px !important;
            }
        }
    
        .react-switch-handle {
            top: 4px !important;
            left: 4px !important;
        }
    }
}

@media (max-width: 575.98px) { 
    .addNewProduct {
        padding: 0;

        .dragBorder {
            .dragWrapper {
                padding: 10px;
            }
        }
    }
}
@media (min-width: 576px) and (max-width: 1024px) {
    .addNewProduct {
        padding: 20px;
    }
}