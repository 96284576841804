.faq-edit-component {
    width: 100%;
    background-image: url('../../../assets/imgs/background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px;
    font-family: "Work Sans", sans-serif;
}

@media (max-width: 575.98px) { 
    .faq-edit-component {
        padding: 30px 20px;
    }
}
@media (min-width: 576px) and (max-width: 768px) {
    .faq-edit-component {
        padding: 30px 20px;
    }
}