@mixin shrinkLabel {
    top: -17px;
    font-size: 13px;
    color: white;    
}

.form-select-container {
    position: relative;
    margin: 30px 0;
    cursor: pointer;

    &.forPayment {
        margin: 3px 0;
    }



    .form-select {
        width: 100%;
        height: 50px;
        border-radius: 30px;
        border: 2px solid #a3a3a3;
        padding: 10px 20px 10px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.forPayment {
            border-width: 1.5px;
            height: 56px;
            color: #a3a3a3;
            font-size: 16px;
        }

        &.red-outline {
            border-width: 1px;
            box-shadow: 0 0 5px 3px #ff3547;
        }
    
        .label-span {
            font-weight: 500;
            color: #a0a0a0;
        }
    
        .select-down-icon {
            color: #a0a0a0;
        }
    }

    .option-div {
        position: absolute;
        border: 1.5px solid #a3a3a3;
        border-radius: 10px;
        border-top: none;
        margin: 0 3%;
        width: 94%;
        background-color: #5f5f5f;
        z-index: 8;
        max-height: 175px;
        overflow: auto;
        // -ms-overflow-style: none;

        // &::-webkit-scrollbar {
        //     display: none;
        // }
          
        .option-item {
            padding: 5px 20px;
            color: grey;
            font-weight: 500;
            border-bottom: 1.5px solid #a3a3a3;

            &:hover {
                background-color: grey;
                color: white;
            }

            &:first-child {
                border-radius: 10px 10px 0 0;
            }

            &:last-child {
                border: none;
                border-radius: 0 0 10px 10px;
            }
        }
    }

    .form-input-label { 
        color: #a0a0a0;
        font-size: 18px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 25px;
        top: 13px;
        transition: 300ms ease all;

        &.shrink {
            @include shrinkLabel();
        }
    }
}