.prodTypeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
    border-radius: 5px;
    border: 1.5px solid grey;
    background-color: #535353; 
    color: #a0a0a0;
    margin-bottom: 30px;

    &:focus {
        outline: 0;
    }

    &.active {
        border-color: #cb5c69;
        color: #eeeeee;
    }

    .pay-icon {
        font-size: 26px;
    }

    span {
        margin-left: 10px;
        font-size: 18px;
    }
}