.product-listing-page {
    width: 100%;
    background-image: url('../../assets/imgs/background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 25px;
    font-family: "Work Sans", sans-serif;

    .headerRow {
        padding-bottom: 20px;

        .form-button {
            height: 44px;
        }
    }    

    label {
        margin-right: 20px; 
        margin-bottom: 0;   
        color: #a3a3a3;

        &.filterLabel {
            font-size: 12px;
            margin-right: 3px;
        }

        &.ml-1 {
            font-size: 14px;
        }
    }

    .non-row {
        padding: 15px 0 15px 10px;
        margin: 0 -15px 0 -15px;
        color: #eeeeee;
        background-color: #666666;
    }

    .section {
        width: 100%;
        background-color: #5f5f5f;
        padding: 20px 30px 30px 30px;

        .filterIndex {                          
            display: flex;
            justify-content: left;
            align-items: center;

            label:first-of-type {
                color: white;
                font-size: 14px;
            }

            .form-select-container {
                margin: 0 20px 0 0;
        
                .form-select {
                    border: 1px solid #767676;
                    height: auto;
                    width: 120px;
                    padding: 4px 10px 4px 15px;

                    .select-down-icon {
                        font-size: 12px;
                        margin-top: 2px;
                    }
                }

                .option-div {
                    border: 1px solid #767676;

                    .option-item {
                        padding: 3px 10px 3px 15px;
                        border-width: 1px;
                    }
                }
            }
        }

        .toolbar {
            padding: 30px 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            
            .leftDiv {
                display: flex;
                justify-content: left;
                flex-wrap: wrap;
            }
        }

        // .deleteAction {
        //     color: #a3a3a3;

        //     .fas {
        //         cursor: pointer;
        //         margin-left: 10px;
        //     }
        // }

        .table-header {
            padding: 15px 0 15px 10px;
            align-items: center;
            background-color: #6a6a6a;
            border-bottom: 2px solid #555;
            font-size: 12px;
            letter-spacing: 0.7px;

            // .thumb {
            //     margin: 0 40px 0 -50px;
            // }
        }     
    }
}

@media (max-width: 575.98px) { 
    .product-listing-page {
        padding: 20px;

        .mobile-hidden {
            display: none;
        }

        .section {
            padding: 20px;

            .toolbar {
                padding: 20px 0;

                .leftDiv {
                    .table-filter-input {
                        padding: 0;

                        input {
                            width: auto;
                            padding: 10px;
                        }
                    }
                }
            }

            .filterIndex {
                .form-select-container {
                    margin: 0 15px 0 0;

                    .form-select {
                        width: 100px;
                    }
                }
            }
        }
    }
}

@media (min-width: 576px) and (max-width: 850px) {
    .product-listing-page {
        padding: 20px 15px;

        .mobile-hidden {
            display: none;
        }

        .section {
            padding: 20px;

            .toolbar {
                padding: 20px 0;

                .leftDiv {
                    .table-filter-input {
                        margin: 0 0 0 10px;
                        padding: 0;

                        input {
                            width: auto;
                            padding: 10px 15px;
                        }
                    }
                }
            }

            .filterIndex {
                .form-select-container {
                    margin: 0 15px 0 0;

                    .form-select {
                        width: 100px;
                    }
                }
            }
        }

        label {
            font-size: 15px;
        }
    }
}