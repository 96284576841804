.leftDiv {
    &.flexTop {
        align-items: flex-start;

        .shipping {
            margin-left: 10px;
            .form-select-container {
                .form-select {
                    width: 180px !important;
                    height: 45px;
                    font-size: 15px;
                    border-radius: 5px;
                }

                .option-div {
                    width: 100%;
                    border-radius: 5px;
                    margin: 0;
                    .option-item {
                        font-size: 14px;

                        &:first-of-type {
                            border-radius: 5px 5px 0 0;
                        }

                        &:last-of-type {
                            border-radius: 0 0 5px 5px;
                        }
                    }                
                }
            }
        }

        // .shippingStateLabels {
        //     font-size: 12px !important;
        //     margin-right: 3px !important;
        //     color: #a3a3a3 !important;
        // }
        
    }
}

@media (max-width: 575.98px) {
    .filterIndex {
        &.shipping {
            margin-bottom: 10px;
        }
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .filterIndex {
        &.shipping {
            margin-bottom: 10px;
        }
    }
}