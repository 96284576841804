.markups-page {
    width: 100%;
    background-image: url('../../assets/imgs/background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 30px;
    font-family: "Work Sans", sans-serif;

   
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .form-button {
            width: 140px;
            height: 40px;
        }
    }

    .toolbar * {
        display: inline-block;
        margin-bottom: 10px;
    }
    .section {
        background-color: #5f5f5faa;
        padding: 20px 30px;

        p {
            color: #a3a3a3;

            .fas {
                cursor: pointer;
            }
        }       

        .table-header {
            padding: 15px 0 15px 10px;
            align-items: center;
            background-color: #6a6a6a;
            border-bottom: 2px solid #555;
            font-size: 12px;
            letter-spacing: 0.7px;
        }        

  
    }  

    .add-new {
        padding: 100px 0;
        background-color: #5f5f5faa;

        .add-section {

            .mb-4 {
                padding: 0 7px;
            }

            .group {
                margin: 0;

                .form-input {
                    margin: 0;
                }

                .form-input-label {
                    left: 35px
                }
               
            }

            .form-select-container {
                margin: 0;

                .form-select {
                    border-width: 1.5px;
                }
            }
        }
    }

}


@media (max-width: 575.98px) { 

    .markups-page {
        padding: 20px 10px 10px 10px;

        .section {
            padding: 20px 10px;

            .table-header {
                padding-left: 0;

                .col-1, .col-2, .col-3, .col-4 {
                    padding-right: 0;
                }
            }
        }
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .markups-page {
        padding: 20px 10px 10px 10px;
    }
}