.int-buttons {
  margin: 10px 0;
  width: 100%;
  height: 50px;
  border-radius: 30px;
  border: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.grey{
    background-color: transparent;
    border: 1px solid white;
    color: white;
  }

  &.remove{
    background-color: rgb(51, 51, 51);
    color: white;
    color: white;
    margin-top: 20px;
  }


  &.success{
    background-color: green;
  }

  &.cancel{
    background-color: red;
    color: white;
    margin-top: 20px;
  }

  @media screen and (min-width: 768px) {
    margin: 0;
  }
}