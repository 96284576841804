.user-edit-component {
    width: 100%;
    background-image: url('../../../assets/imgs/background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: "Work Sans", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;

    .edit-section {
        .no-margin-form-input {         
            .group {
                margin: 0 !important;

                .form-input {
                    margin: 0 !important;
                }
            }            
        }

        .antdCustomSingleSel {
            width: 100% !important;
          
            .ant-select-selector {
                color: #a0a0a0 !important;
                font-size: 16px !important;
                padding: 10px 20px !important;
                min-height: 52px !important;    
                background: transparent !important;
                border: 1.5px solid #a3a3a3 !important;
                border-radius: 30px !important;
            }
        }
    }

    @mixin shrinkLabel {
        top: -17px;
        font-size: 13px;
        color: white;    
    }

    .phone-group {
        position: relative;
        margin: 30px 0;
        font-family: 'PT Sans', sans-serif;

        .phone-input {
            background: none;
            background-color: transparent;
            color: #a0a0a0;
            font-size: 20px;
            padding: 10px 20px 10px 30px;
            display: block;
            width: 100%;
            border: 1.5px solid #a3a3a3;
            border-radius: 30px;
            margin: 0;
            height: 50px;

            &.red-outline {
                border-width: 1px;
                box-shadow: 0 0 5px 3px #ff3547;
            }

            &:focus {
                outline: none;
            }

            &:focus ~ .phone-input-label {
                @include shrinkLabel();
            }
        }

        .phone-input-label { 
            color: #a0a0a0;
            font-size: 18px;
            font-weight: normal;
            position: absolute;
            pointer-events: none;
            left: 25px;
            top: 13px;
            transition: 300ms ease all;

            &.red-label {
                color: #ff3547 !important;
            }

            &.shrink {
                @include shrinkLabel();
            }
        }
    }
}

@media (max-width: 575.98px) { 
    .user-edit-component {
        .edit-section {
            padding: 20px;
        }
    }
}

@media (min-width: 575.98px) and (max-width: 769px) { 
    .user-edit-component {
        .edit-section {
            padding: 40px;
        }
    }
}