.reset-password-page {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-family: 'Work Sans', sans-serif;

    .reqCodeForm {
        padding: 50px;
        width: 500px;
        height: auto;
        background-color: #5f5f5f;
        border-radius: 10px;
        box-shadow: 3px 3px 20px black;
        text-align: center;
    
        .req-title {
            color: white;
            margin-bottom: 45px;
            font-weight: 700;
        }
    
        .passPolicyContainer {
            position: relative;
            z-index: 8;
        }
    }
}


@media (max-width: 575.98px) { 
    .reset-password-page {

        .reqCodeForm {
            padding: 30px;
            width: 340px;
        }
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .reset-password-page {
        padding: 30px;
        .reqCodeForm {
            padding: 40px;
        }
    }
}