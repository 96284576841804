.ffl-row {
    border-bottom: 1px solid #737373;
}

.css-yk16xz-control {
    background-color: transparent !important;
    color: #a3a3a3 !important;
    border: 1px solid grey !important;
}

.css-1uccc91-singleValue {
    color: #a3a3a3 !important;
}

.css-1wa3eu0-placeholder {
    color: #a3a3a3 !important;
}

.css-1okebmr-indicatorSeparator {
    background-color: transparent !important;
}

.button-disabled:disabled{
    background-color: #737373 !important;
    border: 1px solid #737373 !important;
    color: #a3a3a3 !important;
}

.button-disabled[disabled] {
    pointer-events: auto;
}

.text-white{
    color: white !important;
}
.text-red{
    color: red !important;
}