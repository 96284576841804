.faq-page {
    width: 100%;
    background-image: url('../../assets/imgs/background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px;
    font-family: "Work Sans", sans-serif;

    .headerRow {
        padding-bottom: 30px; 

        .form-button {
            height: 44px;
        }
    }

    .add-new {
        padding: 50px;
        background-color: #5f5f5faa;
    }
}

@media (max-width: 575.98px) { 
    .faq-page {
        padding: 30px 20px;

        .add-new {
            padding: 30px 20px;            
        }
    }
}
@media (min-width: 576px) and (max-width: 768px) {
    .faq-page {
        padding: 30px 20px;

        .add-new {
            padding: 30px 20px;            
        }
    }
}