.sold-webinar-page {
    width: 100%;
    background-image: url('../../assets/imgs/background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 25px;
    font-family: "Work Sans", sans-serif;

    .headerRow {
        padding-bottom: 20px;

        .form-button {
            height: 44px;
        }
    }   
    
    label {
        margin-right: 20px; 
        margin-bottom: 0;   
        color: #a3a3a3;

        &.filterLabel {
            font-size: 12px;
            margin-right: 3px;
        }

        &.ml-1 {
            font-size: 14px;
        }
    }

    .non-row {
        padding: 15px 0 15px 10px;
        margin: 0 -15px 0 -15px;
        color: #eeeeee;
        background-color: #666666;
    }

    .section {
        width: 100%;
        background-color: #5f5f5f;
        padding: 20px 30px 30px 30px;

        .toolbar {
            padding: 30px 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            
            .leftDiv {
                display: flex;
                justify-content: left;
                flex-wrap: wrap;
            }
        }

        .table-header {
            padding: 15px 0 15px 10px;
            align-items: center;
            background-color: #6a6a6a;
            border-bottom: 2px solid #555;
            font-size: 12px;
            letter-spacing: 0.7px;
        }     
    }

    .pointer {
        cursor: pointer;
    }
}

@media (max-width: 575.9px) {
    .sold-webinar-page {
        padding: 20px;
        
        .section {
            padding: 20px;
        }
    }
}
