.table-row {
    .customRadioBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #4d4d4d;
        border: 2px solid #7e7e7e;
        width: 25px;
        height: 25px;
        cursor: pointer;

        &.active {
            border-color: #e31c3a;
        }
        
        .customRadioIcon {
            color: #e31c3a;
            font-size: 9px;
        }
    }
}