.leftDiv {
    &.flexTop {
        align-items: flex-start;

        .shipping {
            margin-left: 10px;
            .form-select-container {
                .form-select {
                    width: 180px !important;
                    height: 45px;
                    font-size: 15px;
                    border-radius: 5px;
                }

                .option-div {
                    width: 100%;
                    border-radius: 5px;
                    margin: 0;
                    .option-item {
                        font-size: 14px;

                        &:first-of-type {
                            border-radius: 5px 5px 0 0;
                        }

                        &:last-of-type {
                            border-radius: 0 0 5px 5px;
                        }
                    }                
                }
            }
        }

        // .shippingStateLabels {
        //     font-size: 12px !important;
        //     margin-right: 3px !important;
        //     color: #a3a3a3 !important;
        // }
        
    }
}

@media (max-width: 575.98px) {
    .filterIndex {
        &.shipping {
            margin-bottom: 10px;
        }
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .filterIndex {
        &.shipping {
            margin-bottom: 10px;
        }
    }
}
.order-products-button{
    margin-left: 10px;
    outline: none;
    width: auto;
    height: 45px;
    color: white;
    padding: 10px;
    border-radius: 0 5px 5px 0;
    border: 1px solid #767676;
    background-color: #717171;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}


.right-title-texts{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    & > h3{
        font-size: 20px;
        color: rgba(255,255,255,0.6);

        & > span{
            color: #FFC107;
        }
    }
}

.search-filter-input-custom{
    & > .form-select-container{
        margin: 0 10px 0 0 !important;
    }
    & > .table-filter-input{
        margin-left: 0 !important;
        padding-left: 0 !important;
    }
}

@media (max-width: 768px) {

    .order-products-button{
        margin-left: 0px;
        margin: 10px 5px;
    }

    .right-title-texts{
        flex-direction: column;
        align-items: flex-start;

        & > h3:nth-of-type(2){
            display: none;
        }
        & > h3{
            font-size: 18px;
        }
    }
}