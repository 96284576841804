.completed-webinar-page {
    width: 100%;
    background-image: url('../../assets/imgs/background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.select-filter-opts{
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    height: 45px;
    color: white;
    background-color: #717171;
    border-radius: 7px;
    padding: 5px 10px;
    cursor: pointer;
}

.check-ffl{
    width: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    height: 45px;
    color: white;
    background-color: #717171;
    border-radius: 7px;
    padding: 5px 10px;
    & > input{
        cursor: pointer;
        width: 22px;
        height: 22px;
    }
}