.headerRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    svg {
        margin-top: -4px;
        margin-right: 3px;
    }
}

.fflValidationContinueBtn {
    width: 100%;
    height: 50px;
    border-radius: 30px;
    border: none;
    background-color: #e49f1c;
    color: white;
    font-weight: bold;

    &:focus {
        outline: 0;
    }
}

.fflValidationCancelBtn {
    width: 100%;
    height: 50px;
    border-radius: 30px;
    border: none;
    background-color: #e41c39;
    color: white;
    font-weight: bold;

    &:focus {
        outline: 0;
    }
}

.winnerBtn {
    width: 100%;
    height: 50px;
    border-radius: 30px;
    border: none;
    background-color: #e41c39;
    color: white;
    font-weight: bold;

    &:focus {
        outline: 0;
    }
}

.hotSeatBtn {
    width: 65%;
    height: 35px;
    border-radius: 30px;
    border: none;
    background-color: #e49f1c;
    color: black;
    font-weight: bold;
    font-size: revert;
}
.webinarImg {
    width: 80px;
    height: 76px;
    border-radius: 3px;
    margin-left: 10px;
}
.removeDialogWrapper {
    z-index: 100 !important;
}

@media (max-width: 450px) {
    .webinarImg {
        margin-left: 0;
        width: 70px;
        height: 66px;
    }
}

