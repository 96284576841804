
.form-button {
    width: 100%;
    height: 50px;
    border-radius: 30px;
    border: none;
    background-color: #e41c39;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

 
    &.greyColor {
        background-color: #707070;
    }

    &.disable {
        background-color: #909090;
    }

    &:focus {
        outline: none;
    }

    .loadSubIcon {
        margin-right: 7px;
        animation: spin 1s infinite linear;
    }
}

.form-button:hover {
    background-color: #d06d7b;
    &.greyColor {
        background-color: #ada9a9;
    }
  }