.tooltip-custom {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  width: 220px;
  max-width: 300px;
  height: auto;
  z-index: 9999999;
  padding: 5px 10px;
  background-color: black;
  color: white !important;

  &__back-gray {
    background-color: rgb(58, 58, 58);
  }

  &__back-white {
    background-color: white;
    color: rgba(0, 0, 0, 0.8) !important;
  }

  &__back-blue {
    background-color: #46a9c7;
    color: white !important;
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 10px;

    & > i {
      font-size: 22px;
      margin: 0;
    }

    &__description {
      text-align: start;
      font-size: 18px;
      margin: 0;
    }
  }

  & > i {
    font-size: 25px;
    place-self: flex-end;
  }

  .btn-close-tooltip {
    width: 100% !important;
    border-radius: 5px !important;
    margin-top: 5px !important;
    background-color: black;
    font-weight: 600;
  }

  @media screen and (min-width: 800px) {
    & {
      left: -100px;

      &__content {
        & > i {
          display: none;
        }
      }
    }
  }
}

.long-item-with-tooltip {
  width: auto;
  height: auto;
  text-wrap: balance;
  overflow-wrap: break-word;
}
