.antdCustomSel {
  width: 100% !important;

  .ant-select-selector {
    padding: 10px 20px !important;
    min-height: 50px !important;    
    background: transparent !important;
    border: 1.5px solid #a3a3a3 !important;
    border-radius: 30px !important;
  }
}

.titleTable {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    padding: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 600;
  }

  button {
    width: 140px;
  }
    // button.ant-btn-bns {
    //   width: initial;
    //   padding-right: 40px;
    //   padding-left: 40px;
    //   margin-right: 20px;
    // }
  // }
}

@media (max-width: 575px) { 
  .titleTable {
    p {
      font-size: 20px;
      font-weight: 300;
    }
    button {
      width: 100px;
    }
  }
}

@media (min-width: 576px) and (max-width: 860px) {
  .titleTable {
    p {
      font-size: 26px;
      font-weight: 400;
    }
    button {
      width: 120px;
    }
  }
}