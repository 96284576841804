.editorClassName {
    // overflow: auto;
    // box-sizing: border-box; 
    background-color: #ffffff;
    height: 275px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;    
}

.wrapperClassName {
    color: black !important
}

.toolbarClassName {
    color: black !important
}