.dashboard-page {
    width: 100%;
    background-image: url('../../assets/imgs/background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px;
    box-sizing: border-box; 
    font-family: "Work Sans", sans-serif;

    .graph-wrapper {
       
        background-color: #555555aa;
        margin: 10px 0;
        padding: 10px 0;
        position: relative;

        .customDatePicker {
            position: absolute;            
            top: 20px;
            right: 20px;
            z-index: 100;
            background-color: #3b3b3b;
            padding: 20px 14px 8px 14px;
            border-radius: 5px;

            button {
                background-color: #717171;
                color: white;
                font-size: 12px;
                padding: 2px 8px;
                border: 1.2px solid #a3a3a3;
                border-radius: 30px;

                &:hover {
                    border-color: white;
                    background-color: #a3a3a3;
                }

                &:focus {
                    outline: 0;
                }
            }

            .dateWrapper {
                display: flex;
                flex-wrap: wrap;
            }
        }

        .totalVal {
            padding: 0;
            margin: 0;
            font-size: 13px;
        }

        .graph-header {
            padding: 20px;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;

            p {
                font-size: 20px;
                font-weight: 400;
                margin: 0;
            }

            .form-select-container {                
                margin: 0;

                .form-select {
                    height: auto;
                    // min-width: 150px;
                    border: 1px solid #a3a3a3;
                    padding: 5px 15px;
                    font-size: 14px;

                    .fa {
                        font-size: 12px;
                        margin-left: 20px;
                    }
                }

                .option-div {
                    border-width: 1px;
                    // max-height: 150px;
                    .option-item {
                        padding: 5px 15px;
                        font-size: 14px;
                        border-width: 1px;
                    }
                }
            }
        }

        // .recharts-cartesian-grid-horizontal {
        //     display: none;
        // }
    }
    
}


@media (max-width: 575.98px) { 

    .dashboard-page {
        padding: 10px;

        .graph-wrapper {
            margin: 5px 0;

            .graph-header {
                padding: 10px 20px;
            }

            .customDatePicker {
                width: 360px;
            }
        }
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .dashboard-page {
        padding: 10px;

        .graph-wrapper {
            margin: 5px 0;
            
            .graph-header {
                padding: 10px 20px;
            }
        }
    }
}
