.modal-profile-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  padding: 20px 10px;
  position: fixed;
  top: 0;
  left: 0;

  & > .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;

    .int {
      color: black !important;
    }

    .error-message {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px 0;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 10px 0;
      font-size: 20px;
      border-radius: 7px;

      & > h3 {
        margin: 0 !important;
        padding: 0 !important;
      }
    }

    .leaderboard {
      max-width: 490px;
      width: 100%;
      border-radius: 12px;

      header {
        --start: 15%;

        height: 130px;
        background-image: repeating-radial-gradient(
            circle at var(--start),
            transparent 0%,
            transparent 10%,
            rgba(54, 89, 219, 0.33) 10%,
            rgba(54, 89, 219, 0.33) 17%
          ),
          linear-gradient(to right, #5b7cfa, #3659db);
        color: #fff;
        position: relative;
        border-radius: 12px 12px 0 0;
        overflow: hidden;

        .leaderboard__title {
          position: absolute;
          z-index: 2;
          top: 50%;
          right: calc(var(--start) * 0.75);
          transform: translateY(-50%);
          text-transform: uppercase;
          margin: 0;

          span {
            display: block;
          }

          &--top {
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 6.5px;
          }

          &--bottom {
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 3.55px;
            opacity: 0.65;
            transform: translateY(-2px);
          }
        }

        .leaderboard__icon {
          fill: rgba(255, 255, 255, 0.619);
          opacity: 0.35;
          width: 50px;
          position: absolute;
          top: 50%;
          left: var(--start);
          transform: translate(-50%, -50%);
        }
      }

      &__profiles {
        max-height: 350px;

        overflow-y: scroll;
        display: block;
        scroll-behavior: smooth;
        background-color: rgba(255, 255, 255, 0.308);
        color: #000 !important;
        border-radius: 0 0 12px 12px;
        padding: 15px 15px 20px;
        display: grid;
        row-gap: 8px;
      }

      &__profile {
        display: grid;

        align-items: center;
        padding: 10px 30px 10px 10px;

        overflow: hidden;
        border-radius: 10px;
        border: 5px solid rgba(118, 115, 115, 0.267);

        box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
        cursor: pointer;
        transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98),
          box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
        background-color: rgb(255, 255, 255);
        opacity: 0.5;
      }

      &__picture {
        max-width: 100%;
        width: 60px;
        border-radius: 50%;
        box-shadow: 0 0 0 10px #ebeef3, 0 0 0 22px #f3f4f6;
      }

      &__name {
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 0.64px;
        margin-left: 12px;
      }

      &__name1 {
        color: rgb(240, 237, 237);

        align-items: center;
        padding: 10px 30px 10px 10px;

        overflow: hidden;
        border-radius: 10px;
        border: 5px solid rgb(191, 23, 23);

        box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
        cursor: pointer;
        transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98),
          box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
        background-color: #010000 !important;

        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      &__value {
        color: #35d8ac;
        font-weight: 700;
        font-size: 34px;
        text-align: right;

        & > span {
          opacity: 0.8;
          font-weight: 600;
          font-size: 13px;
          margin-left: 3px;
        }
      }
    }

    .close-icon {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 4px;
      cursor: pointer;
      color: white;
      font-size: 40px;
    }

    & > form {
      width: 100%;
      height: 100%;
      background-color: #444444;
      padding: 10px;
      height: auto;
      overflow-y: scroll;
      border-radius: 15px;
    }
    .modal-map-container {
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      & > .wrapper-map-modal > h3 {
        color: black !important;
      }
    }
    .modal-input-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      width: 100%;
      justify-content: center;
      align-items: center;

      &-small {
        width: 100%;
      }
      .form-select-container {
        margin: 10px 0px !important;
        width: 100% !important;
      }

      .select-down-icon {
        margin-left: 10px !important;
      }

      & > div {
        width: 100%;
        & > *.group {
          margin-top: 10px !important;
          margin-bottom: 10px !important;
          width: 100%;
        }
      }
    }

    &.confirm {
      width: 650px;
      padding: 50px 120px;
    }

    &.unverified-form {
      width: 600px;
      padding: 50px 120px;

      .icon-div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 40px;

        .unverified-icon {
          font-size: 8em;
          color: #a0a0a0;
        }
      }

      p {
        color: #a0a0a0;
        margin-bottom: 50px;

        span {
          color: #e41c39;
        }
      }

      h1 {
        font-size: 3em;
      }
    }

    .changeP {
      margin-top: -25px;
      margin-left: 20px;
    }

    #hidden-input-field {
      display: none;
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .modal-profile-form {
    .content {
      & > form {
        max-width: 550px;
        width: 550px;
      }
      &.confirm {
        padding: 60px 20px;
      }
    }
  }
}

@media (min-width: 840px) {
  .modal-profile-form {
    padding: 20px 0;
    .content {
      & > form {
        margin: 0 auto;
        max-width: 700px;
        width: 700px;
      }
      &.confirm {
        padding: 60px 20px;
      }
    }
  }
}
