.faq-item-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .faqIcon {
        font-size: 16px;
        color: #eeeeee;
        cursor: pointer;
        margin-left: 14px;

        &.del {
            font-size: 20px;
        }
    }

    .faq-item {
        padding: 20px 30px;
        border: 1.5px solid #a3a3a3;
        width: 100%;
        border-radius: 40px;
    
        div {
            &.question {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #d0d0d0;
                margin-top: 0 !important;
                font-size: 17px;
    
                p {
                    margin: 0;
                    width: calc(100% - 30px) !important;
                }
    
                button {
                    background-color: transparent;
                    border: 1.5px solid #eeeeee;
                    border-radius: 50%;         
                    color: #eeeeee;  
                    cursor: pointer;
                    width: 26px;
                    height: 26px;
                    padding: 0;
                    
                    &:focus {
                        outline: 0;
                    }

                }
            }
    
            &.answer {
                margin-top: 20px;
                color: #aaaaaa;
                font-size: 16px;
                word-break: break-word;
            }
        }
    }
}

@media (max-width: 575.98px) { 
    .faq-item {            
        div {
            &.question{
                font-size: 15px;
            }

            &.answer {
                font-size: 14px;
            }            
        }
    }
    
}
