.table-row {            
    padding: 15px 0 15px 10px;
    align-items: center;
    font-size: 13px;
    color: #a3a3a3;  
    
    &:nth-child(even){
        background-color: #666666;
    }


    img {
        width: 50px;
        height: 48px;
        border-radius: 3px;
    } 

    .non-img {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        color: #a3a3a3;
    }

    .streamBtn {
        background-color: #545454;
        color: white;
        font-size: 12px;
        padding: 4px 8px;
        border: 1.2px solid #e41c39;
        border-radius: 30px;

        &:focus {
            outline: 0;
        }

        &:hover {
            border: 1.2px solid #e41c39;
            background-color: #e41c39;
        }
    }
    .streamBtnCopy {
        background-color: #545454;
        color: white;
        font-size: 12px;
        padding: 4px 8px;
        border: 1.2px solid #1f1ce4;
        border-radius: 30px;

        &:focus {
            outline: 0;
        }

        &:hover {
            border: 1.2px solid#1f1ce4;
            background-color: #471ce4;
        }
    }
    .streamBtnShip {
        background-color: #545454;
        color: white;
        font-size: 12px;
        padding: 4px 8px;
        border: 1.2px solid #1ce469;
        border-radius: 30px;

        &:focus {
            outline: 0;
        }

        &:hover {
            border: 1.2px solid#1ce444;
            background-color: #1ce45b;
        }
    }
    
    .goLiveWrapper {
        position: relative;

        .goLiveDiv {
            position: absolute;
            left: -260px;
            top: -80px ;
            display: flex;
            align-items: center ;

            .content {
                padding: 20px ;                
                width: 260px ;
                background-color: white ;
                border-radius: 10px ;
                z-index: 10 ;

                .closeIcon {
                    cursor: pointer ;
                }
                
                button {
                    background-color: #e41a35 ;
                    border: none ;
                    font-size: 16px ;
                    padding: 8px 20px ;
                }

                input {
                    width: 100% ;
                    background-color: #f1f1f1 ;
                    border: 1px solid lightgrey ;
                    border-radius: 30px ;
                    height: 40px ;
                    padding: 0 20px ;
                    color: grey ;

                    &:focus {
                        outline: 0 ;
                    }
                }
            }
        }

        .triangleIcon {
            font-size: 22px ;
            color: white ;
            margin-left: -1px ;
        }
    }
}

@media (max-width: 575.98px) {
    .table-row {
        .goLiveWrapper {    

            .goLiveDiv {
                left: -70px;
                top: -190px;
            }
    
            .triangleIcon {
                display: none;
            }
        }
    }    
}

@media (min-width: 576px) and (max-width: 769px) {
    .table-row {
        .goLiveWrapper {    

            .goLiveDiv {
                left: -200px;
                top: -80px ;
            }
        }
    }    
}