.product-edit {
    width: 100%;
    background-image: url('../../../assets/imgs/background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 25px;
    font-family: "Work Sans", sans-serif;
    
    input{
        background-color: #5f5f5f;
    }

    p {
        color: #a3a3a3;
        margin-bottom: 5px;

        &.label-p {
            font-size: 14px;
        }
    }

    .row {
        margin: 0;
    }

    .headerRow {
        padding-bottom: 20px;

        .form-button {
            height: 44px;
        }
    }

    .section {
        width: 100%;
        background-color: #5f5f5f;
        padding: 20px 30px 30px 30px;

        .status-div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .status-group {
                display: flex;
                justify-content: right;

                .status-wrapper {
                    padding: 0 0 0 30px;
    
                    p {
                        font-size: 12px;
                        margin-bottom: 0;
                    }
                }
            }
    
            // .react-switch-bg {
            //     div {
            //         &:first-of-type {
            //             color: white;
            //             font-weight: 700;
            //             left: 14px !important;
            //             top: 4px !important;
            //         }
    
            //         &:last-of-type {
            //             color: white;
            //             font-weight: 700;
            //             top: 4px !important;
            //             right: 20px !important;
            //         }
            //     }
            // }
        }

        .taxable-wrapper {
            display: flex;
            align-items: center;

            p {
                padding: 0;
                margin: 0 10px;
            }
        }

        .custom-datepicker {
            width: 100%;
            background-color: #777777;
            border-radius: 5px;
            color: #a3a3a3;
        
            .datepicker-header {
                border: 1px solid lightgray;
                border-radius: 5px;
                padding: 10px 15px;
            }
        
            .date-select {
                display: flex;
                justify-content: space-between;
                padding: 0 70px;
        
                p {
                    cursor: context-menu;
        
                    &.pointer {
                        cursor: pointer;
                    }
                }
            }
        
            .form-button {
                background-color: #8c8c8c;
            }
        
        }

        .custom-input {
            background-color: #555;
            border: 1px solid grey;
            border-radius: 5px;
            width: 100%;
            height: 40px;
            padding: 10px 15px;
            color: #ddd;

            &:focus {
                outline: 0;
            }
        }

        .form-select-container {
            margin: 0;

            .form-select {
                background-color: #555;
                height: 40px;
                border-radius: 5px;
                border: 1px solid grey;
                padding: 10px 15px;
                color: #ddd;

                &:focus {
                    outline: 0;
                }

                .select-down-icon {
                    color: #ddd;
                }

                .label-span {
                    color: #ddd;
                }
            }

            .option-div {
                border: 1px solid grey;
                border-radius: 5px;
                margin: 0;
                width: 100%;
                background-color: #555;

                .option-item {
                    border-bottom: 1px solid grey;

                    &:first-of-type {
                        border-radius: 0;
                    }

                    &:last-of-type {
                        border-radius: 0;
                        border-bottom: none;
                    }
                }
            }
        }

        textarea {
            overflow: auto;
            resize: none;
            width: 100%;
            border: 1px solid grey;
            border-radius: 5px;
            padding: 15px;
            max-height: 300px;
            background-color: #555;
            color: #ddd;
            -ms-overflow-style: none;
    
            &::-webkit-scrollbar {
                display: none;
            }
    
            &:focus {
                outline: 0;
            }
        }

        hr {
            background-color: grey;
        }
    }

    .photo-div {
        background-color: #6f6f6f;
        padding: 10px 14px;
        border-radius: 5px;
        
        &:first-of-type {
            margin-bottom: 15px;
        }

        // &:last-of-type {
        //     min-height: calc(100% - 315px);
        // }

        .non-image {
            width: 100%;
            height: 280px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px dashed #a3a3a3;
            color: #a3a3a3;
            font-size: 24px;
            font-weight: 700;

            &.gallery {
                height: 100px;
            }
        }

        .active-img {
            border-radius: 5px;
            width: 100%;
            height: calc(100% - 30px);
        }

        .gallery-item {
            position: relative;
            padding: 5px;
            
            img {
                width: 100%;
                height: 100%;
                border-radius: 3px;
            }

            .checkIcon {
                position: absolute;
                bottom: 12px;
                left: 12px;
                color: #99ffee;
                visibility: hidden;
                cursor: pointer;
                font-size: 22px;

                &:hover {
                    font-weight: 800;
                }
            }
            .removeIcon {
                position: absolute;
                bottom: 12px;
                right: 12px;
                color: #bd1919;
                font-size: 22px;
                cursor: pointer;
                visibility: hidden;

                &:hover {
                    font-weight: 800;
                }
            }

            &:hover {
                img {
                    border-color: #99ffee;
                    box-shadow: 0 0 5px 3px #99ffee;
                }                              

                .checkIcon {
                    visibility: visible;
                }
                .removeIcon {
                    visibility: visible;
                }
            }
        }

        .add-gallery {
            color: white;
            font-size: 14px;
            margin-top: 10px;
            cursor: pointer;

            span {
                &:first-of-type {
                    text-decoration: underline;
                }

                &:last-of-type {
                    font-size: 9px;
                    color: #aaaaaa;
                }
            }

        }
    }
    
}


@media (max-width: 575.98px) { 

    .product-edit{
        .mobile-gal {
            padding: 10px 0 !important;
        }
    
        .section {
            padding: 10px;

            .custom-datepicker {
                .date-select {
                    padding: 0 20px;
                }
            }
        }  
    }    
}

@media (min-width: 576px) and (max-width: 865px) {
    
    .product-edit{
        .mobile-gal {
            padding: 20px 0 !important;
        }

        .section {

            .custom-datepicker {
                .date-select {
                    padding: 0 40px;
                }
            }
        }  
    }
}