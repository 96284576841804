.add-ffl-form {
    padding: 40px 140px;

    .group {
        margin: 15px 0;

        .form-input {
            margin: 0;
            padding-right: 20px;
            height: 50px;
        }
    }

    @mixin shrinkLabel {
        top: -17px;
        font-size: 13px;
        color: white;    
    }

    .phone-group {
        position: relative;
        margin: 15px 0;
        font-family: 'PT Sans', sans-serif;

        .phone-input {
            background: none;
            background-color: transparent;
            color: #a0a0a0;
            font-size: 20px;
            padding: 10px 20px 10px 30px;
            display: block;
            width: 100%;
            border: 1.5px solid #a3a3a3;
            border-radius: 30px;
            margin: 0;
            height: 50px;

            &.red-outline {
                border-width: 1px;
                box-shadow: 0 0 5px 3px #ff3547;
            }

            &:focus {
                outline: none;
            }

            &:focus ~ .phone-input-label {
                @include shrinkLabel();
            }
        }

        .phone-input-label { 
            color: #a0a0a0;
            font-size: 18px;
            font-weight: normal;
            position: absolute;
            pointer-events: none;
            left: 25px;
            top: 13px;
            transition: 300ms ease all;

            &.red-label {
                color: #ff3547 !important;
            }

            &.shrink {
                @include shrinkLabel();
            }
        }
    }

    .background-date-wrapper {
        border-radius: 30px;
        padding: 0 20px;
        border: 1.5px solid #a3a3a3;
        color: #a3a3a3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        margin: 15px 0;

        .react-datepicker-wrapper {
            input {
                width: 100%;
                background-color: transparent;
                border: none;
                color: #a3a3a3;
    
                &:focus {
                    outline: 0;
                }

                &::placeholder {
                    color: #a3a3a3;
                    font-family: 'PT Sans', sans-serif;
                    font-size: 18px;
                }
        
                &::-webkit-input-placeholder {
                    color: #a3a3a3;
                    font-family: 'PT Sans', sans-serif;
                    font-size: 18px;
                }
                
                &::-moz-placeholder {  /* Firefox 19+ */
                    color: #a3a3a3;
                    font-family: 'PT Sans', sans-serif;
                    font-size: 18px;
                }
                
                &:-ms-input-placeholder {
                    color: #a3a3a3;
                    font-family: 'PT Sans', sans-serif;
                    font-size: 18px;
                }
            }
        }

        .datePickerIcon {
            padding-left: 10px;
            cursor: pointer;
            border-left: 1px solid grey;
        }
    }

    .dragBorder {
        display: flex;
        align-items: center;
        width: 100%;
        height: 120px;
        padding: 7px;
        border-radius: 40px;
        border: 2px dashed #a3a3a3;
        cursor: pointer;

        .dragWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #505050;
            padding: 30px;
            width: 100%;
            height: 100%;
            border-radius: 30px;

            p {
                color: #a3a3a3;
                margin-bottom: 0;
            }
        }

        .activeImg {
            margin-left: 5px;
            border-radius: 24px;
            padding: 3px;
            border: 1.2px solid #99ffee;
            width: 120px;
            height: 100px;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                border-radius: 20px;
            }

            label {
                color: #99ffee;
                position: absolute;
                bottom: 5%;
                left: 50%;
                transform: translate(-50%);
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
}

@media (max-width: 575.98px) {
    .add-ffl-form {
        padding: 20px 10px;
    }
}


@media (min-width: 575.98px) and (max-width: 850px) {
    .add-ffl-form {
        padding: 20px 10px;
    }
}