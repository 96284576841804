.checkbox-div {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 5px;
    border: 1px solid grey;
    background-color: #555555;
    cursor: pointer;
    position: relative;

    &:hover {                   
        border: 1px solid lightgrey;
    }

    &.checked {
        background-color: grey;
        border-color: lightgrey;
    }

    .checked-icon {
        position: absolute;
        top: 4px;
        left: 3px;
        font-size: 12px;
        color: lightgrey;
        
    }
}