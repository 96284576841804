.non-img {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: #a3a3a3;
    width: 45px;
    height: 45px;
    border-radius: 10px;
    margin-right: 10px;
}