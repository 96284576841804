.seat-refund-dialog {
    width: 100%;
    background-image: url('../../assets/imgs/background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: "Work Sans", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;

    .seat-refund-wrapper {
        width: 400px;
        border-radius: 20px;
        background-color: #666666;
        padding: 50px;

        .seat-refund-check-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #e41c39;
            font-size: 60px;
        }

        p {
            color: #a3a3a3;
        }
    }
}

.total-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: #555555;
    border: 1.5px solid #666666;
    border-radius: 30px;
    padding: 0 20px;

    p {
        margin: 0;

        &:first-of-type {
            color: white;
        }

        &:last-of-type {
            color: #e41c39;
            padding-left: 12px;
            border-left: 1px solid #666666;
        }
    }
}

@media (max-width: 575.98px) { 
    .ffl-db-page {
        padding: 20px 10px;
        .section {
            padding: 20px;
    
            .table-filter-input {

                input {
                    padding: 10px;
                }
                
                button {
                    padding: 10px 15px;
                }
            }
        }
    }    
}