.container-spinner{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(255,255,255,0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    .spinner {
      width: 40px;
      height: 40px;
      position: relative;
      margin: 100px auto;
    }
    
    .spinner .bounce1, .spinner .bounce2, .spinner .bounce3 {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #333;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      
      -webkit-animation: sk-bounce 1.4s infinite ease-in-out both;
      animation: sk-bounce 1.4s infinite ease-in-out both;
    }
    
    .spinner .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
    
    .spinner .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
    
    @-webkit-keyframes sk-bounce {
      0%, 100% { -webkit-transform: scale(0.0) }
      50% { -webkit-transform: scale(1.0) }
    }
    
    @keyframes sk-bounce {
      0%, 100% { 
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
      } 50% { 
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
      }
    }
    

}
