.custom-elipsis-text{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.add-new {
    padding: 100px;
    background-color: #5f5f5faa;
    position: relative;

    .add-section {

        .antdCustomSingleSel {
            width: 100% !important;
          
            .ant-select-selector {
                color: #a0a0a0 !important;
                font-size: 16px !important;
                padding: 10px 20px !important;
                min-height: 52px !important;    
                background: transparent !important;
                border: 1.5px solid #a3a3a3 !important;
                border-radius: 30px !important;
            }
        }

        .mb-4 {
            padding: 0 10px;
        }

        .group {
            margin: 0;

            .form-input {
                margin: 0;
            }

            .form-input-label {
                left: 35px
            }
           
        }

        .form-select-container {
            margin: 0;

            .form-select {
                border-width: 1.5px;
            }
        }
    }

    .closeIcon {
        position: absolute;
        top: 24px;
        right: 30px;
        cursor: pointer;
        font-size: 30px;
        color: white;
    }
}

@media (max-width: 575.98px) {

    .add-new {
        padding: 20px 10px;
    }
 
    .section {
        padding: 20px;

        .toolbar {
            padding: 20px 0;

            .leftDiv {
                .table-filter-input {
                    padding: 0;

                    input {
                        width: auto;
                        padding: 10px;
                    }
                }
            }
        }

        .filterIndex {
            .form-select-container {
                margin: 0 15px 0 0;
            }
        }
    }
    
}


@media (min-width: 576px) and (max-width: 860px) {

    .add-new {
        padding: 50px 30px;
    }

    .section {
        padding: 20px;

        .toolbar {
            padding: 20px 0;

            .leftDiv {
                .table-filter-input {
                    margin: 0 0 0 10px;
                    padding: 0;

                    input {
                        width: auto;
                        padding: 10px;
                    }

                    button {
                        padding: 10px;
                    }
                }
            }
        }

        .filterIndex {
            .form-select-container {
                margin: 0 15px 0 0;
            }
        }
    }
}
